import React from "react";
import { cn } from "@/lib/utils";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import Icon from "./icon";

export type ColorVariant =
  | "gray"
  | "orange"
  | "yellow"
  | "purple"
  | "red"
  | "green"
  | "blue"
  | "pink"
  | "turquoise";

interface CustomAlertProps {
  title: string;
  description: string;
  variant: ColorVariant;
  iconClassName?: string;
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  showCloseIcon?: boolean;
  icon?: string;
}

const getColorClasses = (variant: ColorVariant) => ({
  background: `bg-${variant}-100`,
  border: `border-${variant}-300`,
  icon: `bg-${variant}-300`,
  title: `text-${variant}-500`,
  description: `text-${variant}-300`,
  closeIcon: `text-${variant}-300 border-${variant}-300`,
  iconShadow: `drop-shadow-md shadow-${variant}-200`,
});

export function CustomAlert({
  title,
  description,
  variant,
  iconClassName,
  className,
  titleClassName,
  descriptionClassName,
  icon = "information-fill",
  showCloseIcon = false,
}: CustomAlertProps) {
  const colors = getColorClasses(variant);

  return (
    <Alert
      className={cn(
        "flex min-w-[327px] h-auto w-full items-center gap-2 rounded-xl py-2 px-[10px]",
        colors.background,
        `border ${colors.border}`,
        "shadow-[0_0_0_2px_#FFF,0_0_0_3px_#F4F4F5]",
        className
      )}
    >
      <div
        className={cn(
          "rounded-full p-1 max-h-[32px] flex justify-center items-center",
          colors.icon
        )}
      >
        <Icon
          icon={icon}
          className={cn("h-6 w-6 text-white", colors.iconShadow, iconClassName)}
        />
      </div>
      <div className="flex items-start justify-between w-full">
        <div className="flex flex-col">
          <AlertTitle
            className={cn(
              "!text-body-2xs-m !mb-0",
              colors.title,
              titleClassName
            )}
          >
            {title}
          </AlertTitle>
          <AlertDescription
            className={cn(
              "text-[10px] font-normal leading-[14px] tracking-[-0.1px]",
              colors.description,
              descriptionClassName
            )}
          >
            {description}
          </AlertDescription>
        </div>
        {showCloseIcon && (
          <div
            className={cn(
              "flex items-center w-5 h-5 bg-white rounded-[5px] p-[2px] border",
              colors.closeIcon
            )}
          >
            <Icon icon="close-line" />
          </div>
        )}
      </div>
    </Alert>
  );
}
