import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import OrderStatus from "./order/order-status";
import actions from "@/store/actions";
import { useSnapshot } from "valtio";
import state, { OrderStatus as OrderStatusResponse } from "@/store/state";
import { Skeleton } from "@/components/ui/skeleton";

export function OrderStatusPage() {
  const [orderId, setOrderId] = useState<string | null>(null);
  const { isLoading, error } = useSnapshot(state);
  const [orderStatus, setOrderStatus] = useState<OrderStatusResponse | null>(
    null
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get("orderID");
    setOrderId(id);

    if (id) {
      actions.fetchOrderStatus(id).then(setOrderStatus);
    }
  }, [searchParams]);

  return (
    <div className="min-h-screen mt-[48px] max-w-2xl mx-auto space-y-4 p-4 bg-gray-50">
      {isLoading ? (
        <div className="space-y-4">
          <Skeleton className="h-52 w-full" />
          <Skeleton className="h-32 w-full" />
        </div>
      ) : (
        <OrderStatus orderStatus={orderStatus} />
      )}
    </div>
  );
}

export default OrderStatusPage;
