import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion"; // Framer Motion'ı import edin
import { Services } from "@/store/state";
import { StatItem } from "./stat-item";
import { FollowersIcon, LikesIcon, ViewsIcon, SharesIcon } from "./stat-icons";
import { StatDrawer } from "./stat-drawer";
import { DrawerState } from "@/types/drawer-states";

const statConfigs = [
  {
    service: Services.FOLLOWERS,
    icon: FollowersIcon,
    label: "Followers",
    sublabel: "boost your presence!",
    color: "bg-gray-800",
  },
  {
    service: Services.LIKES,
    icon: LikesIcon,
    label: "Likes",
    sublabel: "skyrocket your engagement!",
    color: "bg-red-500",
  },
  {
    service: Services.VIEWS,
    icon: ViewsIcon,
    label: "Views",
    sublabel: "viralize your content!",
    color: "bg-green-500",
  },
  {
    service: Services.SHARES,
    icon: SharesIcon,
    label: "Shares",
    sublabel: "expand your reach!",
    color: "bg-orange-500",
  },
];

interface AccountStatsCardProps {
  [key: string]: string;
}

export function AccountStatsCard(props: AccountStatsCardProps) {
  const [drawerState, setDrawerState] = useState<DrawerState>(
    DrawerState.CLOSED
  );
  const [selectedService, setSelectedService] = useState<Services | null>(null);
  const controls = statConfigs.map(() => useAnimation());

  const handleStatItemSelect = (service: Services) => {
    setSelectedService(service);
    setDrawerState(DrawerState.SERVICE_SELECTION);
  };

  useEffect(() => {
    const sequence = async () => {
      for (let i = 0; i < controls.length; i++) {
        await controls[i].start({
          scale: [1, 1.05, 1],
          transition: { duration: 0.6 },
        });
        // Wait for 1 second before starting the next animation
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    };

    sequence();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-3">
        {statConfigs.map((config, index) => (
          <motion.div key={config.service} animate={controls[index]}>
            <StatItem
              icon={config.icon}
              label={config.label}
              sublabel={config.sublabel}
              value={props[config.service]}
              color={config.color}
              service={config.service as Services}
              onSelect={() => handleStatItemSelect(config.service as Services)}
            />
          </motion.div>
        ))}
      </div>
      <StatDrawer />
    </>
  );
}
