import OpenReplay from "@openreplay/tracker";
import Mixpanel from "@/utils/mixpanel";
import state from "@/store/state";

// Initialize OpenReplay with extended options
const tracker = new OpenReplay({
  projectKey: "eHYlojEHlLZpOXXakvhI", // Replace with your actual project key
  capturePerformance: true,
  network: {
    capturePayload: true,
  },
  crossdomain: {
    enabled: true,
  },
  defaultInputMode: 0,
  obscureTextNumbers: false,
  obscureTextEmails: false,
  obscureInputEmails: false,
  obscureInputDates: false,
});

// Start OpenReplay tracking
tracker.start();

interface DataLayerObject {
  event: string;
  email?: string;
  currency: string;
  transaction_id?: string;
  value: number;
  items: Array<{
    item_id: string;
    item_brand: string;
    index?: number;
    item_name: string;
    item_variant: string;
    item_category: string;
    price: number;
    quantity: number;
  }>;
}
export function trackBeginCheckout(totalAmount: number) {
  const dataLayerObject: DataLayerObject = {
    event: "begin_checkout",
    currency: "USD",
    value: totalAmount,
    items: [
      {
        item_id:
          state.cart[0]?.products[state.currentSelectedService!]?.[0]
            ?.service_id || "",
        item_brand: "TikTok",
        index: 0,
        item_name: "TikTok Consulting",
        item_variant: "Follower-Like",
        item_category: "TikTok",
        price: totalAmount,
        quantity: 1,
      },
    ],
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerObject);

  // Add Mixpanel tracking
  Mixpanel.track("Begin Checkout", {
    totalAmount: totalAmount,
    currency: "USD",
    itemId:
      state.cart[0]?.products[state.currentSelectedService!]?.[0]?.service_id ||
      "",
    itemName: "TikTok Consulting",
    itemCategory: "TikTok",
  });
}

export function trackPurchase(
  paymentIntent: string,
  email: string,
  totalAmount: number
) {
  const dataLayerObject: DataLayerObject = {
    event: "purchase",
    email: email,
    currency: "USD",
    transaction_id: paymentIntent,
    value: totalAmount,
    items: [
      {
        item_id:
          state.cart[0]?.products[state.currentSelectedService!]?.[0]
            ?.service_id || "",
        item_brand: "TikTok",
        index: 0,
        item_name: "TikTok Consulting",
        item_variant: "Follower-Like",
        item_category: "TikTok",
        price: totalAmount,
        quantity: 1,
      },
    ],
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerObject);

  // Mixpanel tracking
  Mixpanel.track("Purchase", {
    distinct_id: email,
    paymentIntent: paymentIntent,
    totalAmount: totalAmount,
    currency: "USD",
    itemId:
      state.cart[0]?.products[state.currentSelectedService!]?.[0]?.service_id ||
      "",
    itemName: "TikTok Consulting",
    itemCategory: "TikTok",
  });

  Mixpanel.setUserProperties({
    $email: email,
    $last_purchase_date: new Date().toISOString(),
  });

  // Identify user in OpenReplay
  tracker.setUserID(email);

  if ("OpenReplay" in window && window.OpenReplay) {
    window.OpenReplay.setMetadata("mixpanelDistanceID", email);
  }
}

export function trackAccountSelection() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "accountSelected",
  });

  Mixpanel.track("Account Selected");
}

export function trackOrderPageLoad() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "orderPageLoad",
  });

  Mixpanel.track("Order Page Loaded");
}

export function trackStartProcessPayment() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "startProcessPayment",
  });

  Mixpanel.track("Start Process Payment");
}

export function trackAddToCart(productPrice: number, serviceId: string) {
  const dataLayerObject: DataLayerObject = {
    event: "add_to_cart",
    currency: "USD",
    value: productPrice,
    items: [
      {
        item_id: serviceId,
        item_brand: "TikTok",
        item_name: "TikTok Consulting",
        item_variant: "Follower-Like",
        item_category: "TikTok",
        price: productPrice,
        quantity: 1,
      },
    ],
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerObject);

  Mixpanel.track("Add to Cart", {
    productPrice: productPrice,
    serviceId: serviceId,
    itemName: "TikTok Consulting",
    itemCategory: "TikTok",
  });
}

export function trackPageView(pageName: string) {
  Mixpanel.track("Page View", {
    pageName: pageName,
    url: window.location.href,
  });
}
