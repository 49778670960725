import { Services } from "@/store/state";

export enum DrawerState {
  CLOSED = "CLOSED",
  SERVICE_SELECTION = "SERVICE_SELECTION",
  SERVICE_SPECIFIC = "SERVICE_SPECIFIC",
}

export const getServiceSpecificState = (service: Services): DrawerState => {
  if (
    service === Services.LIKES ||
    service === Services.VIEWS ||
    service === Services.SHARES
  ) {
    return DrawerState.SERVICE_SPECIFIC;
  }
  return DrawerState.SERVICE_SELECTION;
};
