import * as React from "react";
import { cn } from "@/lib/utils";
import { Input, InputProps } from "@/components/ui/input";
import Icon from "./icon";

export interface CustomInputProps extends Omit<InputProps, "onChange"> {
  error?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  showLeftIcon?: boolean;
  showRightIcon?: boolean;
  loading?: boolean;
  onChange?: (value: string | React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onCustomSubmit?: (value: string) => void;
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
  (
    {
      className,
      error,
      leftIcon = <Icon icon="search-2-line" className="w-5 h-5" />,
      rightIcon,
      showLeftIcon = true,
      showRightIcon = false,
      loading = false,
      onChange,
      onClear,
      onCustomSubmit,
      value,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<string>(
      value?.toString() || ""
    );

    React.useEffect(() => {
      setInputValue(value?.toString() || "");
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      if (onChange) {
        onChange(event);
      }
    };

    const handleClear = () => {
      setInputValue("");
      if (onClear) {
        onClear();
      }
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (onCustomSubmit) {
          onCustomSubmit(inputValue);
        }
        (event.target as HTMLInputElement).blur();
      }
    };

    return (
      <div
        className={cn(
          "relative flex items-center rounded-lg border bg-white gap-2 pl-[10px]",
          "shadow-[0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_0px_0px_2px_#FFF,0px_0px_0px_3px_#E4E4E7,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset,0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_46px_13px_0px_rgba(0,0,0,0.00)_inset,0px_29px_12px_0px_rgba(0,0,0,0.00)_inset,0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset,0px_1px_2px_0px_rgba(228,229,231,0.24)]",
          error
            ? "border-red-300"
            : cn(
                "border-gray-200",
                "hover:border-gray-300 hover:shadow-[0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset,0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_46px_13px_0px_rgba(0,0,0,0.00)_inset,0px_29px_12px_0px_rgba(0,0,0,0.00)_inset,0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset,0px_1px_2px_0px_rgba(228,229,231,0.24)]",
                "focus-within:border-gray-900 focus-within:shadow-[0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_0px_0px_2px_#FFF,0px_0px_0px_3px_#E4E4E7,0px_1px_2px_0px_rgba(228,229,231,0.24),0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset,0px_46px_13px_0px_rgba(0,0,0,0.00)_inset,0px_29px_12px_0px_rgba(0,0,0,0.00)_inset,0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset]",
                "focus-within:hover:border-gray-900 focus-within:hover:shadow-[0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_0px_0px_2px_#FFF,0px_0px_0px_3px_#E4E4E7,0px_1px_2px_0px_rgba(228,229,231,0.24),0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset,0px_46px_13px_0px_rgba(0,0,0,0.00)_inset,0px_29px_12px_0px_rgba(0,0,0,0.00)_inset,0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset]"
              ),
          className
        )}
      >
        {showLeftIcon && (
          <div
            className={cn(
              "transition-colors duration-100",
              isFocused ? "text-gray-700" : "text-gray-400"
            )}
          >
            {leftIcon}
          </div>
        )}
        <Input
          className={cn(
            "flex-grow text-sm py-[10px] pl-[0] pr-2",
            "border-0 shadow-none bg-transparent",
            "focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
            "placeholder:text-gray-500",
            error && "text-red-300"
          )}
          ref={ref}
          value={inputValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          {...props}
        />
        {loading ? (
          <div className="flex-shrink-0 ml-2 mr-3 text-purple-300">
            <Icon icon="loader-2-fill" className="w-5 h-5 animate-spin" />
          </div>
        ) : inputValue ? (
          <button
            onClick={handleClear}
            className="flex-shrink-0 ml-2 mr-3 text-gray-400 hover:text-gray-600"
          >
            <Icon icon="close-circle-line" className="w-5 h-5" />
          </button>
        ) : (
          rightIcon && (
            <div className="flex-shrink-0 ml-2 mr-3 text-gray-400">
              {rightIcon}
            </div>
          )
        )}
      </div>
    );
  }
);

CustomInput.displayName = "CustomInput";

export { CustomInput };
