import React, { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import state, {
  Followers,
  PostBasedProduct,
  Services,
  Steps,
} from "@/store/state";
import { ChooseServiceProfileCard } from "./choose-service-profile-card";
import { AccountStatsCard } from "./account-stats-card";
import { CustomAlert } from "./custom-alert";
import { BasketSummary } from "./basket-summary";
import actions from "@/store/actions";
import { priceList } from "@/config";
import { trackOrderPageLoad } from "@/utils/analytics";
import { PromiseCarousel } from "./promise-carousel";

interface ChooseServiceProps {
  onContinue: () => void;
  onBack: () => void;
}

export function ChooseService({ onContinue, onBack }: ChooseServiceProps) {
  const { user, cart, lastAddedService } = useSnapshot(state);
  const [showBasket, setShowBasket] = useState(false);

  useEffect(() => {
    trackOrderPageLoad();
  }, []);

  useEffect(() => {
    if (lastAddedService.service && lastAddedService.amount > 0) {
      setShowBasket(true);
    }
  }, [lastAddedService]);

  if (!user) return null;

  const userCart = cart.find((item) => item.username === user.username);
  const hasSelectedServices =
    userCart && Object.keys(userCart.products).length > 0;

  const handleGoToBasket = () => {
    onContinue();
  };

  const calculateTotalPrice = () => {
    if (!userCart) return { original: 0, discounted: 0 };
    let originalTotal = 0;
    let discountedTotal = 0;

    Object.entries(userCart.products).forEach(([service, products]) => {
      products.forEach((product) => {
        const packInfo = priceList[service as Services].find(
          (pack) => pack.id.toString() === product.service_id
        );
        if (packInfo) {
          if (service === Services.FOLLOWERS) {
            const followerProduct = product as Followers;
            originalTotal += packInfo.originalPrice * followerProduct.count;
            discountedTotal += packInfo.price * followerProduct.count;
          } else {
            const postBasedProduct = product as PostBasedProduct;
            const postCount = postBasedProduct.posts.length;
            originalTotal += packInfo.originalPrice * postCount;
            discountedTotal += packInfo.price * postCount;
          }
        }
      });
    });

    return { original: originalTotal, discounted: discountedTotal };
  };

  const { original: originalPrice, discounted: discountedPrice } =
    calculateTotalPrice();

  return (
    <div className="flex flex-col gap-2 h-full">
      <div className="flex-grow p-6 bg-gray-50 border-t border-b border-gray-100">
        <div className="mx-auto">
          <ChooseServiceProfileCard
            followers={user.totalFollower}
            likes={user.totalLike}
            avatarUrl={user.profilePicture}
            isVerified={true}
            className="mb-4"
          />
          <AccountStatsCard
            followers={user.totalFollower.toString()}
            likes={user.totalLike.toString()}
            views="0"
            shares="0"
          />
          <PromiseCarousel className="mt-4" />
        </div>
      </div>
      {/* {state.showBasketSummary && (
        <div className="max-w-2xl mx-auto fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100">
          <BasketSummary
            itemCount={getTotalItemCount()}
            originalPrice={`$${originalPrice.toFixed(2)}`}
            discountedPrice={`$${discountedPrice.toFixed(2)}`}
            onGoToPayment={handleGoToBasket}
          />
        </div>
      )} */}
    </div>
  );
}
