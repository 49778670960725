import React from "react";
import { Services, Followers, PostBasedProduct } from "@/store/state";
import { useSnapshot } from "valtio";
import state from "@/store/state";
import actions from "@/store/actions";
import Icon from "./icon";
import { DrawerState } from "@/types/drawer-states";
import { ErrorDialog } from "./ErrorDialog";

interface StatItemProps {
  icon: () => JSX.Element;
  label: string;
  sublabel: string;
  value: string;
  color: string;
  service: Services;
  onSelect: () => void;
}

export function StatItem({
  icon,
  label,
  sublabel,
  value,
  color,
  service,
  onSelect,
}: StatItemProps) {
  const store = useSnapshot(state);
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSelect = () => {
    actions.setCurrentSelectedService(service);
    if (
      service === Services.LIKES ||
      service === Services.VIEWS ||
      service === Services.SHARES
    ) {
      const { isValid, error } = actions.checkUserFeed();
      if (isValid) {
        actions.setDrawerState(DrawerState.SERVICE_SELECTION);
        onSelect();
      } else {
        setErrorMessage(error || "An unknown error occurred.");
        setShowErrorDialog(true);
      }
    } else {
      actions.setDrawerState(DrawerState.SERVICE_SELECTION);
      onSelect();
    }
  };

  const selectedCount = React.useMemo(() => {
    const serviceProducts = store.user?.username
      ? store.cart.find((item) => item.username === store.user?.username)
          ?.products[service]
      : null;
    return (
      serviceProducts?.reduce((total, product) => {
        if (service === Services.FOLLOWERS) {
          return total + (product as Followers).count;
        } else {
          return total + (product as PostBasedProduct).posts.length;
        }
      }, 0) ?? 0
    );
  }, [store.user, store.cart, service]);

  return (
    <>
      <div
        onClick={handleSelect}
        className="group flex items-center w-full gap-[12px] px-3 h-[56px] py-2 border border-gray-100 bg-white rounded-[12px] cursor-pointer transition-shadow duration-300 ease-in-out hover:shadow-[0px_46px_13px_0px_rgba(0,0,0,0.00),_0px_29px_12px_0px_rgba(0,0,0,0.01),_0px_17px_10px_0px_rgba(0,0,0,0.03),_0px_7px_7px_0px_rgba(0,0,0,0.05),_0px_2px_4px_0px_rgba(0,0,0,0.06)]"
      >
        <div className="flex items-center justify-center">{icon()}</div>
        <div className="flex-grow">
          <div className="text-body-xs-r text-gray-400">
            Buy TikTok{" "}
            <span className="text-body-xs-m text-gray-900">{label}</span>
          </div>
          <div className="text-body-2xs-r text-gray-400">{sublabel}</div>
        </div>
        <div className="flex items-center">
          {selectedCount > 0 && (
            <div className="bg-yellow-300 border border-white text-body-2xs-m text-white rounded-full w-5 h-5 flex items-center justify-center mr-3 shadow-[-3px_4px_4px_-2px_rgba(19,19,19,0.20)]">
              {selectedCount}
            </div>
          )}
          <div className="border w-6 h-6 border-gray-100 rounded-[6px] bg-gray-50 flex items-center">
            <Icon icon="arrow-right-line" className="w-5 h-5 text-gray-900" />
          </div>
        </div>
      </div>
      <ErrorDialog
        open={showErrorDialog}
        onOpenChange={setShowErrorDialog}
        errorMessage={errorMessage}
      />
    </>
  );
}
