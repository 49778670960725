interface Purchase {
  name: string;
}

export const recentPurchases: Purchase[] = [
  { name: "Jack S." },
  { name: "Emma L." },
  { name: "Noah P." },
  { name: "Sophia R." },
  { name: "Oliver T." },
  { name: "Ava M." },
  { name: "Lucas K." },
  { name: "Isabella H." },
  { name: "Liam B." },
  { name: "Mia D." },
  { name: "Henry W." },
  { name: "Charlotte N." },
  { name: "William F." },
  { name: "Amelia J." },
  { name: "James C." },
  { name: "Harper V." },
  { name: "Benjamin Q." },
  { name: "Evelyn Z." },
  { name: "Mason Y." },
  { name: "Luna X." },
  { name: "Ethan A." },
  { name: "Chloe B." },
  { name: "Alexander C." },
  { name: "Zoe D." },
  { name: "Daniel E." },
  { name: "Grace F." },
  { name: "Michael G." },
  { name: "Sophie H." },
  { name: "David I." },
  { name: "Emily J." },
  { name: "Joseph K." },
  { name: "Victoria L." },
  { name: "Thomas M." },
  { name: "Lily N." },
  { name: "Samuel O." },
  { name: "Hannah P." },
  { name: "Christopher Q." },
  { name: "Aria R." },
  { name: "Andrew S." },
  { name: "Scarlett T." },
  { name: "Joshua U." },
  { name: "Madison V." },
  { name: "Nathan W." },
  { name: "Layla X." },
  { name: "Ryan Y." },
  { name: "Nora Z." },
  { name: "Dylan A." },
  { name: "Claire B." },
  { name: "Adrian C." },
  { name: "Bella D." },
  { name: "Isaac E." },
  { name: "Julia F." },
  { name: "Owen G." },
  { name: "Violet H." },
  { name: "Gabriel I." },
  { name: "Audrey J." },
  { name: "Carter K." },
  { name: "Maya L." },
  { name: "Leo M." },
  { name: "Alice N." },
  { name: "Julian O." },
  { name: "Elena P." },
  { name: "Max Q." },
  { name: "Ruby R." },
  { name: "Chase S." },
  { name: "Hazel T." },
  { name: "Austin U." },
  { name: "Sarah V." },
  { name: "Caleb W." },
  { name: "Autumn X." },
  { name: "Ian Y." },
  { name: "Stella Z." },
  { name: "Adam A." },
  { name: "Lucy B." },
  { name: "Miles C." },
  { name: "Eva D." },
  { name: "Felix E." },
  { name: "Naomi F." },
  { name: "Cole G." },
  { name: "Willow H." },
  { name: "Axel I." },
  { name: "Ivy J." },
  { name: "Wesley K." },
  { name: "Paisley L." },
  { name: "Finn M." },
  { name: "Jade N." },
  { name: "Zane O." },
  { name: "Rose P." },
  { name: "Kai Q." },
  { name: "Daisy R." },
  { name: "Theo S." },
  { name: "Piper T." },
  { name: "Atlas U." },
  { name: "Eden V." },
  { name: "River W." },
  { name: "Faith X." },
  { name: "Phoenix Y." },
  { name: "Hope Z." },
  { name: "Jasper A." },
  { name: "Iris B." },
  { name: "Hugo C." },
  { name: "Dawn D." },
  { name: "Oscar E." },
  { name: "Joy F." }
]; 