import { cn } from "@/lib/utils";
import { Services } from "@/store/state";
import React from "react";
import Icon from "./icon";

interface ChooseServiceGraphProps {
  startValue: number;
  endValue: number;
  service: Services;
  className?: string;
}

const formatter = new Intl.NumberFormat("en", {
  notation: "compact",
  compactDisplay: "short",
});

const serviceConfig = {
  [Services.FOLLOWERS]: {
    fill: "#18181B",
    gradient: "url(#paint0_linear_81_6976)",
    startIcon: "emotion-line",
    endIcon: "emotion-fill",
    endStyle:
      "border-gray-900 bg-gradient-to-b from-white/12 to-white/0 to-100% bg-gray-900",
    textColor: "text-gray-800",
  },
  [Services.LIKES]: {
    fill: "#DF1C41",
    gradient: "url(#paint0_linear_152_7804)",
    startIcon: "heart-3-line",
    endIcon: "heart-3-fill",
    endStyle:
      "border-[#DF1C41] bg-gradient-to-b from-white/12 to-white/0 to-100% bg-[#DF1C41]",
    textColor: "text-red-300",
  },
  [Services.VIEWS]: {
    fill: "#38C793",
    gradient: "url(#paint0_linear_148_29685)",
    startIcon: "eye-line",
    endIcon: "eye-fill",
    endStyle:
      "border-[#38C793] bg-gradient-to-b from-white/12 to-white/0 to-100% bg-[#38C793]",
    textColor: "text-green-300",
  },
  [Services.SHARES]: {
    fill: "#F17B2C",
    gradient: "url(#paint0_linear_148_30137)",
    startIcon: "share-line",
    endIcon: "share-fill",
    endStyle:
      "border-[#F17B2C] bg-gradient-to-b from-white/12 to-white/0 to-100% bg-[#F17B2C]",
    textColor: "text-orange-300",
  },
};

export function ChooseServiceGraph({
  startValue,
  endValue,
  service,
  className,
}: ChooseServiceGraphProps) {
  const formattedStart = formatter.format(startValue);
  const formattedEnd = formatter.format(endValue);
  const formattedGrowth = formatter.format(endValue - startValue);
  const { fill, gradient, startIcon, endIcon, endStyle, textColor } =
    serviceConfig[service];

  const showNumbers =
    service === Services.FOLLOWERS || service === Services.LIKES;

  const getServiceText = () => {
    switch (service) {
      case Services.FOLLOWERS:
        return `Your followers will grow from ${formattedStart} to ${formattedEnd}`;
      case Services.LIKES:
        return `Your likes will grow from ${formattedStart} to ${formattedEnd}`;
      case Services.VIEWS:
        return `Your views will grow by ${formattedGrowth}`;
      case Services.SHARES:
        return `Your shares will grow by ${formattedGrowth}`;
      default:
        return "";
    }
  };

  return (
    <div
      className={cn(
        "relative w-full py-5 pr-[48px] pl-[41px] bg-white rounded-[12px] border border-gray-100 max-h-[154px] flex items-center justify-center",
        className
      )}
    >
      <img
        src="/confetti-left.png"
        alt="Confetti Left"
        width={100}
        height={100}
        className="absolute top-0 -left-0 z-0 opacity-100"
      />
      <img
        src="/confetti-right.png"
        alt="Confetti Right"
        width={100}
        height={100}
        className="absolute bottom-0 right-0 z-0 opacity-100"
      />
      <div className="relative z-10 mt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 247 53"
          fill="none"
          className="w-full h-[48px] md:h-[70px]"
        >
          <path
            d="M235.774 4.99955L0 51.9996H234.526L235.774 4.99955Z"
            fill={gradient}
          />
          <path
            d="M1.05525 51.0181C0.51321 51.124 0.159623 51.6492 0.265494 52.1912C0.371364 52.7333 0.896601 53.0869 1.43864 52.981L1.05525 51.0181ZM247 3.99955L236.079 0.250077L238.293 11.5829L247 3.99955ZM1.43864 52.981L238.359 6.70627L237.976 4.74336L1.05525 51.0181L1.43864 52.981Z"
            fill={fill}
          />
          <defs>
            <linearGradient
              id="paint0_linear_81_6976"
              x1="190.864"
              y1="50.9995"
              x2="189.662"
              y2="2.98281"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.3" />
              <stop offset="1" stopColor={fill} stopOpacity="0.24" />
            </linearGradient>
            <linearGradient
              id="paint0_linear_152_7804"
              x1="190.864"
              y1="50.9995"
              x2="189.662"
              y2="2.98277"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.3" />
              <stop offset="1" stopColor="#E23455" stopOpacity="0.32" />
            </linearGradient>
            <linearGradient
              id="paint0_linear_148_29685"
              x1="197.819"
              y1="50.9993"
              x2="196.658"
              y2="2.98044"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.3" />
              <stop offset="1" stopColor="#4DCD9E" stopOpacity="0.32" />
            </linearGradient>
            <linearGradient
              id="paint0_linear_148_30137"
              x1="197.819"
              y1="50.9993"
              x2="196.658"
              y2="2.98044"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.3" />
              <stop offset="1" stopColor="#F17B2C" stopOpacity="0.32" />
            </linearGradient>
          </defs>
        </svg>
        <div className="absolute bottom-12 mb-[2px] left-0 border-gray-100 bg-gray-50 transform -rotate-[10.515deg]">
          <div
            className={cn(
              "rounded-[88px] border !text-body-2xs-r text-gray-400 flex items-center justify-center",
              showNumbers ? "pt-[2px] pr-[10px] pb-[2px] pl-[6px]" : "p-0"
            )}
          >
            <Icon icon={startIcon} className="w-4 h-4 max-w-4 max-h-4" />
            {showNumbers && <span> {formattedStart}</span>}
          </div>
        </div>

        <div
          className={cn(
            "flex items-center absolute -top-4 right-6 transform -rotate-[10.961deg] rounded-[88px] border text-body-2xs-r",
            endStyle,
            showNumbers ? "pt-[2px] pr-[10px] pb-[2px] pl-[6px]" : "p-0"
          )}
        >
          <Icon icon={endIcon} className="w-5 h-5 text-white" />
          {showNumbers && <span className="text-white"> {formattedEnd}</span>}
        </div>

        <div className="flex justify-center w-full">
          <p
            className={cn(
              "text-center min-w-[242px] flex min-h-[20px] justify-center items-center mt-5 !text-body-2xs-m !bg-gray-50 border border-gray-100 rounded-[88px]",
              textColor
            )}
          >
            {getServiceText()}
          </p>
        </div>
      </div>
    </div>
  );
}
