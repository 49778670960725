import React from "react";
import { cn } from "@/lib/utils";
import Icon from "./icon";
import { Step, Steps } from "@/store/state";

interface StepIndicatorProps {
  currentStep: Step;
  className?: string;
}

export function StepIndicator({ currentStep, className }: StepIndicatorProps) {
  const steps = [
    { label: "Select Account", step: Steps.SELECT_ACCOUNT },
    { label: "Choose Service", step: Steps.CHOOSE_SERVICE },
    { label: "Checkout", step: Steps.CHECKOUT },
  ];

  const currentStepIndex = steps.findIndex(({ step }) => step === currentStep);

  return (
    <div
      className={cn(
        "flex w-full px-3 py-2.5 justify-between min-h-[48px] h-[48px]",
        className
      )}
    >
      {steps.map(({ label, step }, index) => (
        <div key={step} className="flex items-center gap-1 pt-1 pr-3 pb-1 pl-1">
          <div
            className={cn(
              "w-4 h-4 rounded-full flex items-center justify-center",
              step === currentStep
                ? "bg-purple-300 text-white w-5 h-5"
                : index < currentStepIndex
                ? "bg-gray-50 text-purple-300 w-5 h-5"
                : "text-purple-200 bg-gray-50"
            )}
          >
            {step === currentStep ? (
              <Icon icon="loader-fill" className="w-4 h-4 spin-once" />
            ) : index < currentStepIndex ? (
              <Icon icon="checkbox-circle-fill" className="w-4 h-4" />
            ) : (
              <Icon icon="checkbox-blank-circle-line" className="w-4 h-4" />
            )}
          </div>
          <span
            className={cn(
              "text-sm text-[12px] leading-[16px] tracking-[-0.12px] text-nowrap",
              step === currentStep
                ? "text-purple-300 font-normal"
                : index < currentStepIndex
                ? "text-purple-300 font-normal"
                : "text-gray-400"
            )}
          >
            {label}
          </span>
        </div>
      ))}
    </div>
  );
}
