import React, { useEffect } from "react";
import CheckoutBasketItems from "./checkout/checkout-basket-items";
import { StatDrawer } from "./stat-drawer";
import { trackPageView } from "@/utils/analytics";

const CheckoutPage: React.FC = () => {
  useEffect(() => {
    trackPageView("Basket Page");
  }, []);

  return (
    <div className="p-6 pt-0 mt-5">
      <CheckoutBasketItems />
      <StatDrawer />
    </div>
  );
};

export default CheckoutPage;
