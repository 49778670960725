import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { trackPageView } from "@/utils/analytics";

export function HomePage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [iframeSrc, setIframeSrc] = useState(
    () => `https://landing.famepeak.io${location.pathname}${location.search}`
  );

  useEffect(() => {
    trackPageView("Landing Page");
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== "https://landing.famepeak.io") return;

      if (event.data.type === "navigation") {
        const newPath = event.data.path;
        console.log("Received navigation message", event.data);
        navigate(newPath, { replace: true });

        // Track page view for the new path
        trackPageView(newPath);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [navigate]);

  useEffect(() => {
    setIframeSrc(
      `https://landing.famepeak.io${location.pathname}${location.search}`
    );
  }, [location]);

  return (
    <div className="w-full h-screen">
      <iframe
        src={iframeSrc}
        title="Famepeak"
        className="w-full h-full border-none"
        allowFullScreen
      />
    </div>
  );
}
