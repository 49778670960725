import React from "react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import Icon from "./icon";
import { Separator } from "./ui/separator";

type PackType = "default" | "best_value";

interface ServiceCardPackProps {
  amount: number;
  originalPrice: number;
  price: number;
  onSelect: () => void;
  isSelected: boolean;
  className?: string;
  type?: PackType;
  showBadge?: boolean;
  title: string;
}

export function ServiceCardPack({
  amount,
  originalPrice,
  price,
  onSelect,
  isSelected,
  className,
  type = "default",
  showBadge = false,
  title,
}: ServiceCardPackProps) {
  return (
    <div
      className={cn(
        "flex flex-col",
        isSelected && type === "best_value" && "rounded-xl"
      )}
    >
      {type === "best_value" && (
        <div
          className={cn(
            "px-4 flex items-center justify-center text-center !text-body-2xs-m h-5",
            "rounded-t-[12px] relative overflow-hidden",
            isSelected
              ? "bg-purple-300 text-white"
              : "bg-yellow-300 text-gray-900"
          )}
        >
          <div className="absolute inset-0 w-[200%] animate-shine bg-gradient-to-r from-transparent via-white/40 to-transparent"></div>
          <span className="relative z-10 flex items-center">
            <Icon icon="star-s-fill" className="w-3 h-3" />
            <span className="mx-[2px]">Best Value</span>
            <Icon icon="star-s-fill" className="w-3 h-3" />
          </span>
        </div>
      )}
      <Card
        className={cn(
          "flex items-center justify-between px-3 py-3 rounded-t-none cursor-pointer h-12",
          type === "best_value" ? "rounded-b-xl" : "rounded-xl",
          isSelected ? "border-purple-300 border-[1px]" : "border-gray-100",
          className
        )}
        onClick={onSelect}
      >
        <div className="flex items-center space-x-4 w-full">
          <span className="text-body-xs-m text-gray-900 max-w-[193px] text-nowrap w-full">
            +{title}
          </span>
          <div className="flex items-center ml-auto w-full justify-end">
            {showBadge && (
              <>
                <span className="ml-auto w-[61px] h-5 flex justify-center items-center text-center bg-green-300 border border-white text-white text-detail mr-1 rounded-full shadow-[-3px_4px_4px_-2px_rgba(19,19,19,0.20)]">
                  {Math.round(((originalPrice - price) / originalPrice) * 100)}%
                  OFF
                </span>
                <span className="text-gray-400 text-body-2xs-r line-through">
                  ${originalPrice.toFixed(2)}
                </span>
                <Separator orientation="vertical" className="mx-1 h-4" />
              </>
            )}
            <div className="flex items-center">
              <span className="text-gray-900 text-body-2xs-m">
                ${price.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center ml-2">
          {isSelected ? (
            <Icon
              icon="checkbox-circle-fill"
              className="w-5 h-5 text-purple-300"
            />
          ) : (
            <Icon
              icon="checkbox-blank-circle-line"
              className="w-5 h-5 text-gray-300"
            />
          )}
        </div>
      </Card>
    </div>
  );
}
