import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const avatars = [
  { src: "/Avatar.png", alt: "Avatar 1" },
  { src: "/Avatar-1.png", alt: "Avatar 2" },
  { src: "/Avatar-2.png", alt: "Avatar 3" },
  { src: "/Avatar-3.png", alt: "Avatar 4" },
  { src: "/Avatar-4.png", alt: "Avatar 5" },
];

export function AvatarGroup() {
  return (
    <div className="flex -space-x-3 overflow-hidden">
      {avatars.map((avatar, index) => (
        <Avatar
          key={index}
          className="inline-block border-2 border-white rounded-full w-9 h-9"
        >
          <AvatarImage src={avatar.src} alt={avatar.alt} />
          <AvatarFallback>{avatar.alt[0]}</AvatarFallback>
        </Avatar>
      ))}
    </div>
  );
}
