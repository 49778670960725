import React, { useState, useEffect, useMemo } from "react";
import { ServiceCardPack } from "./service-pack-card";
import { cn } from "@/lib/utils";
import { useSnapshot } from "valtio";
import state from "@/store/state";
import { priceList } from "@/config";

export function ServicePackSelector({
  className,
  onPackSelect,
}: {
  className?: string;
  onPackSelect: (packId: number | null) => void;
}) {
  const [selectedPackIndex, setSelectedPackIndex] = useState<number | null>(
    null
  );
  const { currentSelectedService } = useSnapshot(state);

  const servicePacks = useMemo(() => {
    if (!currentSelectedService) return [];

    // Sort the service packs so that the promoted pack comes first
    return [...priceList[currentSelectedService]].sort((a, b) => {
      if (a.promoted && !b.promoted) return -1;
      if (!a.promoted && b.promoted) return 1;
      return 0;
    });
  }, [currentSelectedService]);

  useEffect(() => {
    const promotedPackIndex = servicePacks.findIndex((pack) => pack.promoted);
    if (promotedPackIndex !== -1 && selectedPackIndex === null) {
      setSelectedPackIndex(promotedPackIndex);
      onPackSelect(servicePacks[promotedPackIndex].id);
    }
  }, [servicePacks, onPackSelect, selectedPackIndex]);

  const handlePackSelect = (index: number) => {
    setSelectedPackIndex(index);
    onPackSelect(servicePacks[index].id);
  };

  return (
    <div
      id="service-pack-selector"
      className={cn(
        "flex flex-col gap-4 min-h-[300px] max-h-[100px] pb-5 overflow-y-auto",
        className
      )}
    >
      {servicePacks.map((pack, index) => (
        <ServiceCardPack
          key={pack.id}
          amount={pack.amount}
          originalPrice={pack.originalPrice}
          price={pack.price}
          isSelected={selectedPackIndex === index}
          onSelect={() => handlePackSelect(index)}
          type={pack.promoted ? "best_value" : "default"}
          showBadge={pack.price !== pack.originalPrice && !!pack.discount}
          title={pack.title}
        />
      ))}
    </div>
  );
}
