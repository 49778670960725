import React from "react";

export const AppTopLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97"
    height="18"
    viewBox="0 0 97 18"
    fill="none"
  >
    <path
      d="M9.41563 1.17998C9.5133 1.33731 9.59701 1.55185 9.66678 1.8236C9.75049 2.08105 9.79235 2.3528 9.79235 2.63885C9.79235 3.19666 9.67375 3.59714 9.43656 3.84029C9.21332 4.06913 8.90637 4.18355 8.5157 4.18355H4.12067V6.60787H8.70406C8.81568 6.7652 8.90637 6.97259 8.97613 7.23004C9.05984 7.48748 9.1017 7.75924 9.1017 8.04529C9.1017 8.5888 8.9831 8.98212 8.74591 9.22527C8.52267 9.45411 8.21572 9.56853 7.82505 9.56853H4.16252V14.0954C4.00905 14.1383 3.76488 14.1812 3.43002 14.2241C3.10911 14.267 2.78821 14.2884 2.4673 14.2884C2.14639 14.2884 1.86037 14.2598 1.60922 14.2026C1.37203 14.1597 1.16972 14.0739 1.00229 13.9452C0.834859 13.8164 0.709287 13.6377 0.625572 13.4088C0.541857 13.18 0.5 12.8796 0.5 12.5077V3.17521C0.5 2.56019 0.674406 2.0739 1.02322 1.71633C1.37203 1.35876 1.84641 1.17998 2.44637 1.17998H9.41563Z"
      fill="#6E3FF3"
    />
    <path
      d="M9.41563 1.17998C9.5133 1.33731 9.59701 1.55185 9.66678 1.8236C9.75049 2.08105 9.79235 2.3528 9.79235 2.63885C9.79235 3.19666 9.67375 3.59714 9.43656 3.84029C9.21332 4.06913 8.90637 4.18355 8.5157 4.18355H4.12067V6.60787H8.70406C8.81568 6.7652 8.90637 6.97259 8.97613 7.23004C9.05984 7.48748 9.1017 7.75924 9.1017 8.04529C9.1017 8.5888 8.9831 8.98212 8.74591 9.22527C8.52267 9.45411 8.21572 9.56853 7.82505 9.56853H4.16252V14.0954C4.00905 14.1383 3.76488 14.1812 3.43002 14.2241C3.10911 14.267 2.78821 14.2884 2.4673 14.2884C2.14639 14.2884 1.86037 14.2598 1.60922 14.2026C1.37203 14.1597 1.16972 14.0739 1.00229 13.9452C0.834859 13.8164 0.709287 13.6377 0.625572 13.4088C0.541857 13.18 0.5 12.8796 0.5 12.5077V3.17521C0.5 2.56019 0.674406 2.0739 1.02322 1.71633C1.37203 1.35876 1.84641 1.17998 2.44637 1.17998H9.41563Z"
      fill="url(#paint0_linear_23_493)"
      fill-opacity="0.12"
    />
    <path
      d="M15.2294 11.8641C15.4666 11.8641 15.7247 11.8427 16.0038 11.7998C16.2968 11.7426 16.513 11.671 16.6525 11.5852V9.86889L15.1457 9.99762C14.755 10.0262 14.4341 10.112 14.183 10.2551C13.9318 10.3981 13.8062 10.6126 13.8062 10.8987C13.8062 11.1847 13.9109 11.4207 14.1202 11.6067C14.3434 11.7783 14.7132 11.8641 15.2294 11.8641ZM15.062 3.43266C15.8154 3.43266 16.4991 3.51132 17.113 3.66865C17.7408 3.82598 18.271 4.06913 18.7036 4.39809C19.15 4.71275 19.4919 5.12038 19.7291 5.62098C19.9663 6.10727 20.0849 6.68653 20.0849 7.35876V12.1645C20.0849 12.5364 19.9802 12.8439 19.7709 13.087C19.5756 13.3159 19.3384 13.5161 19.0594 13.6877C18.1524 14.2455 16.8758 14.5244 15.2294 14.5244C14.4899 14.5244 13.8202 14.4529 13.2202 14.3099C12.6342 14.1669 12.125 13.9523 11.6924 13.6663C11.2739 13.3802 10.946 13.0155 10.7088 12.5721C10.4856 12.1287 10.3739 11.6138 10.3739 11.0274C10.3739 10.0405 10.66 9.28248 11.232 8.75328C11.8041 8.22408 12.69 7.89511 13.89 7.76639L16.6316 7.46603V7.31585C16.6316 6.90107 16.4502 6.60787 16.0875 6.43623C15.7387 6.2503 15.2294 6.15733 14.5597 6.15733C14.0295 6.15733 13.5132 6.21454 13.011 6.32896C12.5087 6.44338 12.0552 6.58641 11.6506 6.75805C11.4692 6.62932 11.3157 6.43623 11.1902 6.17878C11.0646 5.90703 11.0018 5.62813 11.0018 5.34207C11.0018 4.9702 11.0855 4.677 11.2529 4.46246C11.4343 4.23361 11.7064 4.04052 12.0692 3.88319C12.4738 3.72586 12.9482 3.61144 13.4923 3.53993C14.0504 3.46841 14.5736 3.43266 15.062 3.43266Z"
      fill="#6E3FF3"
    />
    <path
      d="M15.2294 11.8641C15.4666 11.8641 15.7247 11.8427 16.0038 11.7998C16.2968 11.7426 16.513 11.671 16.6525 11.5852V9.86889L15.1457 9.99762C14.755 10.0262 14.4341 10.112 14.183 10.2551C13.9318 10.3981 13.8062 10.6126 13.8062 10.8987C13.8062 11.1847 13.9109 11.4207 14.1202 11.6067C14.3434 11.7783 14.7132 11.8641 15.2294 11.8641ZM15.062 3.43266C15.8154 3.43266 16.4991 3.51132 17.113 3.66865C17.7408 3.82598 18.271 4.06913 18.7036 4.39809C19.15 4.71275 19.4919 5.12038 19.7291 5.62098C19.9663 6.10727 20.0849 6.68653 20.0849 7.35876V12.1645C20.0849 12.5364 19.9802 12.8439 19.7709 13.087C19.5756 13.3159 19.3384 13.5161 19.0594 13.6877C18.1524 14.2455 16.8758 14.5244 15.2294 14.5244C14.4899 14.5244 13.8202 14.4529 13.2202 14.3099C12.6342 14.1669 12.125 13.9523 11.6924 13.6663C11.2739 13.3802 10.946 13.0155 10.7088 12.5721C10.4856 12.1287 10.3739 11.6138 10.3739 11.0274C10.3739 10.0405 10.66 9.28248 11.232 8.75328C11.8041 8.22408 12.69 7.89511 13.89 7.76639L16.6316 7.46603V7.31585C16.6316 6.90107 16.4502 6.60787 16.0875 6.43623C15.7387 6.2503 15.2294 6.15733 14.5597 6.15733C14.0295 6.15733 13.5132 6.21454 13.011 6.32896C12.5087 6.44338 12.0552 6.58641 11.6506 6.75805C11.4692 6.62932 11.3157 6.43623 11.1902 6.17878C11.0646 5.90703 11.0018 5.62813 11.0018 5.34207C11.0018 4.9702 11.0855 4.677 11.2529 4.46246C11.4343 4.23361 11.7064 4.04052 12.0692 3.88319C12.4738 3.72586 12.9482 3.61144 13.4923 3.53993C14.0504 3.46841 14.5736 3.43266 15.062 3.43266Z"
      fill="url(#paint1_linear_23_493)"
      fill-opacity="0.12"
    />
    <path
      d="M27.1367 3.43266C27.7087 3.43266 28.2668 3.51847 28.811 3.69011C29.3691 3.84744 29.8504 4.09058 30.2551 4.41955C30.6736 4.13349 31.1411 3.8975 31.6573 3.71156C32.1875 3.52562 32.8223 3.43266 33.5618 3.43266C34.092 3.43266 34.6082 3.50417 35.1105 3.6472C35.6268 3.79023 36.0802 4.01907 36.4709 4.33373C36.8755 4.63409 37.1964 5.04172 37.4336 5.55662C37.6708 6.05721 37.7894 6.67223 37.7894 7.40167V14.1597C37.6499 14.2026 37.4266 14.2455 37.1197 14.2884C36.8267 14.3313 36.5197 14.3528 36.1988 14.3528C35.8919 14.3528 35.6128 14.3313 35.3617 14.2884C35.1245 14.2455 34.9222 14.1597 34.7547 14.031C34.5873 13.9023 34.4548 13.7306 34.3571 13.5161C34.2734 13.2872 34.2315 12.994 34.2315 12.6365V7.50894C34.2315 7.07986 34.1129 6.77235 33.8757 6.58641C33.6385 6.38617 33.3176 6.28605 32.913 6.28605C32.7177 6.28605 32.5084 6.33611 32.2852 6.43623C32.0619 6.52205 31.8945 6.61502 31.7829 6.71514C31.7968 6.77235 31.8038 6.82956 31.8038 6.88677C31.8038 6.92968 31.8038 6.97259 31.8038 7.01549V14.1597C31.6503 14.2026 31.4201 14.2455 31.1131 14.2884C30.8201 14.3313 30.5202 14.3528 30.2132 14.3528C29.9063 14.3528 29.6272 14.3313 29.3761 14.2884C29.1389 14.2455 28.9366 14.1597 28.7691 14.031C28.6017 13.9023 28.4692 13.7306 28.3715 13.5161C28.2878 13.2872 28.2459 12.994 28.2459 12.6365V7.50894C28.2459 7.07986 28.1134 6.77235 27.8483 6.58641C27.5971 6.38617 27.2902 6.28605 26.9274 6.28605C26.6763 6.28605 26.46 6.32896 26.2786 6.41478C26.0972 6.48629 25.9438 6.55781 25.8182 6.62932V14.1597C25.6787 14.2026 25.4554 14.2455 25.1485 14.2884C24.8555 14.3313 24.5485 14.3528 24.2276 14.3528C23.9206 14.3528 23.6416 14.3313 23.3904 14.2884C23.1533 14.2455 22.9509 14.1597 22.7835 14.031C22.6161 13.9023 22.4835 13.7306 22.3859 13.5161C22.3022 13.2872 22.2603 12.994 22.2603 12.6365V6.15733C22.2603 5.77116 22.337 5.46365 22.4905 5.2348C22.6579 5.00596 22.8812 4.79142 23.1602 4.59118C23.6346 4.24791 24.2206 3.96901 24.9182 3.75447C25.6298 3.53993 26.3693 3.43266 27.1367 3.43266Z"
      fill="#6E3FF3"
    />
    <path
      d="M27.1367 3.43266C27.7087 3.43266 28.2668 3.51847 28.811 3.69011C29.3691 3.84744 29.8504 4.09058 30.2551 4.41955C30.6736 4.13349 31.1411 3.8975 31.6573 3.71156C32.1875 3.52562 32.8223 3.43266 33.5618 3.43266C34.092 3.43266 34.6082 3.50417 35.1105 3.6472C35.6268 3.79023 36.0802 4.01907 36.4709 4.33373C36.8755 4.63409 37.1964 5.04172 37.4336 5.55662C37.6708 6.05721 37.7894 6.67223 37.7894 7.40167V14.1597C37.6499 14.2026 37.4266 14.2455 37.1197 14.2884C36.8267 14.3313 36.5197 14.3528 36.1988 14.3528C35.8919 14.3528 35.6128 14.3313 35.3617 14.2884C35.1245 14.2455 34.9222 14.1597 34.7547 14.031C34.5873 13.9023 34.4548 13.7306 34.3571 13.5161C34.2734 13.2872 34.2315 12.994 34.2315 12.6365V7.50894C34.2315 7.07986 34.1129 6.77235 33.8757 6.58641C33.6385 6.38617 33.3176 6.28605 32.913 6.28605C32.7177 6.28605 32.5084 6.33611 32.2852 6.43623C32.0619 6.52205 31.8945 6.61502 31.7829 6.71514C31.7968 6.77235 31.8038 6.82956 31.8038 6.88677C31.8038 6.92968 31.8038 6.97259 31.8038 7.01549V14.1597C31.6503 14.2026 31.4201 14.2455 31.1131 14.2884C30.8201 14.3313 30.5202 14.3528 30.2132 14.3528C29.9063 14.3528 29.6272 14.3313 29.3761 14.2884C29.1389 14.2455 28.9366 14.1597 28.7691 14.031C28.6017 13.9023 28.4692 13.7306 28.3715 13.5161C28.2878 13.2872 28.2459 12.994 28.2459 12.6365V7.50894C28.2459 7.07986 28.1134 6.77235 27.8483 6.58641C27.5971 6.38617 27.2902 6.28605 26.9274 6.28605C26.6763 6.28605 26.46 6.32896 26.2786 6.41478C26.0972 6.48629 25.9438 6.55781 25.8182 6.62932V14.1597C25.6787 14.2026 25.4554 14.2455 25.1485 14.2884C24.8555 14.3313 24.5485 14.3528 24.2276 14.3528C23.9206 14.3528 23.6416 14.3313 23.3904 14.2884C23.1533 14.2455 22.9509 14.1597 22.7835 14.031C22.6161 13.9023 22.4835 13.7306 22.3859 13.5161C22.3022 13.2872 22.2603 12.994 22.2603 12.6365V6.15733C22.2603 5.77116 22.337 5.46365 22.4905 5.2348C22.6579 5.00596 22.8812 4.79142 23.1602 4.59118C23.6346 4.24791 24.2206 3.96901 24.9182 3.75447C25.6298 3.53993 26.3693 3.43266 27.1367 3.43266Z"
      fill="url(#paint2_linear_23_493)"
      fill-opacity="0.12"
    />
    <path
      d="M45.3385 14.5244C44.5153 14.5244 43.7479 14.41 43.0363 14.1812C42.3387 13.938 41.7248 13.5805 41.1946 13.1085C40.6783 12.6365 40.2667 12.0429 39.9598 11.3278C39.6668 10.6126 39.5203 9.77592 39.5203 8.81764C39.5203 7.87366 39.6668 7.06555 39.9598 6.39333C40.2667 5.70679 40.6644 5.14899 41.1527 4.7199C41.6411 4.27652 42.1992 3.95471 42.827 3.75447C43.4549 3.53993 44.0967 3.43266 44.7525 3.43266C45.4919 3.43266 46.1617 3.54708 46.7616 3.77592C47.3755 4.00477 47.8987 4.31943 48.3313 4.7199C48.7777 5.12038 49.1196 5.59952 49.3568 6.15733C49.6079 6.71514 49.7335 7.323 49.7335 7.98093C49.7335 8.46722 49.6009 8.83909 49.3358 9.09654C49.0707 9.35399 48.701 9.51847 48.2266 9.58999L43.0572 10.3838C43.2107 10.8558 43.5246 11.2133 43.999 11.4565C44.4734 11.6853 45.0176 11.7998 45.6315 11.7998C46.2035 11.7998 46.7407 11.7282 47.243 11.5852C47.7592 11.4279 48.1778 11.2491 48.4987 11.0489C48.7219 11.1919 48.9103 11.3921 49.0638 11.6496C49.2172 11.907 49.294 12.1788 49.294 12.4648C49.294 13.1085 49.001 13.5876 48.415 13.9023C47.9685 14.1454 47.4662 14.3099 46.9081 14.3957C46.35 14.4815 45.8268 14.5244 45.3385 14.5244ZM44.7525 6.09297C44.4176 6.09297 44.1246 6.15018 43.8734 6.2646C43.6363 6.37902 43.4409 6.5292 43.2874 6.71514C43.134 6.88677 43.0154 7.08701 42.9317 7.31585C42.8619 7.53039 42.82 7.75209 42.8061 7.98093L46.3849 7.38021C46.343 7.09416 46.1896 6.8081 45.9245 6.52205C45.6594 6.236 45.2687 6.09297 44.7525 6.09297Z"
      fill="#6E3FF3"
    />
    <path
      d="M45.3385 14.5244C44.5153 14.5244 43.7479 14.41 43.0363 14.1812C42.3387 13.938 41.7248 13.5805 41.1946 13.1085C40.6783 12.6365 40.2667 12.0429 39.9598 11.3278C39.6668 10.6126 39.5203 9.77592 39.5203 8.81764C39.5203 7.87366 39.6668 7.06555 39.9598 6.39333C40.2667 5.70679 40.6644 5.14899 41.1527 4.7199C41.6411 4.27652 42.1992 3.95471 42.827 3.75447C43.4549 3.53993 44.0967 3.43266 44.7525 3.43266C45.4919 3.43266 46.1617 3.54708 46.7616 3.77592C47.3755 4.00477 47.8987 4.31943 48.3313 4.7199C48.7777 5.12038 49.1196 5.59952 49.3568 6.15733C49.6079 6.71514 49.7335 7.323 49.7335 7.98093C49.7335 8.46722 49.6009 8.83909 49.3358 9.09654C49.0707 9.35399 48.701 9.51847 48.2266 9.58999L43.0572 10.3838C43.2107 10.8558 43.5246 11.2133 43.999 11.4565C44.4734 11.6853 45.0176 11.7998 45.6315 11.7998C46.2035 11.7998 46.7407 11.7282 47.243 11.5852C47.7592 11.4279 48.1778 11.2491 48.4987 11.0489C48.7219 11.1919 48.9103 11.3921 49.0638 11.6496C49.2172 11.907 49.294 12.1788 49.294 12.4648C49.294 13.1085 49.001 13.5876 48.415 13.9023C47.9685 14.1454 47.4662 14.3099 46.9081 14.3957C46.35 14.4815 45.8268 14.5244 45.3385 14.5244ZM44.7525 6.09297C44.4176 6.09297 44.1246 6.15018 43.8734 6.2646C43.6363 6.37902 43.4409 6.5292 43.2874 6.71514C43.134 6.88677 43.0154 7.08701 42.9317 7.31585C42.8619 7.53039 42.82 7.75209 42.8061 7.98093L46.3849 7.38021C46.343 7.09416 46.1896 6.8081 45.9245 6.52205C45.6594 6.236 45.2687 6.09297 44.7525 6.09297Z"
      fill="url(#paint3_linear_23_493)"
      fill-opacity="0.12"
    />
    <path
      d="M56.1131 3.43266C56.9503 3.43266 57.7247 3.54708 58.4362 3.77592C59.1618 3.99046 59.7826 4.32658 60.2989 4.78427C60.8151 5.24195 61.2198 5.82122 61.5128 6.52205C61.8058 7.20858 61.9523 8.03099 61.9523 8.98927C61.9523 9.90465 61.8267 10.7056 61.5755 11.3921C61.3244 12.0787 60.9686 12.6579 60.5082 13.1299C60.0477 13.5876 59.4896 13.9309 58.8339 14.1597C58.1921 14.3886 57.4735 14.503 56.6782 14.503C56.0783 14.503 55.5202 14.41 55.0039 14.2241V17.7855C54.8644 17.8284 54.6412 17.8713 54.3342 17.9142C54.0273 17.9714 53.7133 18 53.3924 18C53.0855 18 52.8064 17.9785 52.5553 17.9356C52.3181 17.8927 52.1158 17.8069 51.9483 17.6782C51.7809 17.5495 51.6553 17.3707 51.5716 17.1418C51.4879 16.9273 51.446 16.6412 51.446 16.2837V5.9857C51.446 5.59952 51.5228 5.28486 51.6763 5.04172C51.8437 4.79857 52.0669 4.57688 52.346 4.37664C52.7785 4.09058 53.3157 3.86174 53.9575 3.69011C54.5993 3.51847 55.3179 3.43266 56.1131 3.43266ZM56.155 11.6281C57.5921 11.6281 58.3107 10.7485 58.3107 8.98927C58.3107 8.0739 58.1293 7.39452 57.7665 6.95113C57.4177 6.50775 56.9084 6.28605 56.2387 6.28605C55.9736 6.28605 55.7364 6.32181 55.5271 6.39333C55.3179 6.45054 55.1365 6.52205 54.983 6.60787V11.3278C55.1504 11.4136 55.3318 11.4851 55.5271 11.5423C55.7225 11.5995 55.9318 11.6281 56.155 11.6281Z"
      fill="#6E3FF3"
    />
    <path
      d="M56.1131 3.43266C56.9503 3.43266 57.7247 3.54708 58.4362 3.77592C59.1618 3.99046 59.7826 4.32658 60.2989 4.78427C60.8151 5.24195 61.2198 5.82122 61.5128 6.52205C61.8058 7.20858 61.9523 8.03099 61.9523 8.98927C61.9523 9.90465 61.8267 10.7056 61.5755 11.3921C61.3244 12.0787 60.9686 12.6579 60.5082 13.1299C60.0477 13.5876 59.4896 13.9309 58.8339 14.1597C58.1921 14.3886 57.4735 14.503 56.6782 14.503C56.0783 14.503 55.5202 14.41 55.0039 14.2241V17.7855C54.8644 17.8284 54.6412 17.8713 54.3342 17.9142C54.0273 17.9714 53.7133 18 53.3924 18C53.0855 18 52.8064 17.9785 52.5553 17.9356C52.3181 17.8927 52.1158 17.8069 51.9483 17.6782C51.7809 17.5495 51.6553 17.3707 51.5716 17.1418C51.4879 16.9273 51.446 16.6412 51.446 16.2837V5.9857C51.446 5.59952 51.5228 5.28486 51.6763 5.04172C51.8437 4.79857 52.0669 4.57688 52.346 4.37664C52.7785 4.09058 53.3157 3.86174 53.9575 3.69011C54.5993 3.51847 55.3179 3.43266 56.1131 3.43266ZM56.155 11.6281C57.5921 11.6281 58.3107 10.7485 58.3107 8.98927C58.3107 8.0739 58.1293 7.39452 57.7665 6.95113C57.4177 6.50775 56.9084 6.28605 56.2387 6.28605C55.9736 6.28605 55.7364 6.32181 55.5271 6.39333C55.3179 6.45054 55.1365 6.52205 54.983 6.60787V11.3278C55.1504 11.4136 55.3318 11.4851 55.5271 11.5423C55.7225 11.5995 55.9318 11.6281 56.155 11.6281Z"
      fill="url(#paint4_linear_23_493)"
      fill-opacity="0.12"
    />
    <path
      d="M69.0672 14.5244C68.244 14.5244 67.4766 14.41 66.765 14.1812C66.0674 13.938 65.4535 13.5805 64.9233 13.1085C64.4071 12.6365 63.9955 12.0429 63.6885 11.3278C63.3955 10.6126 63.249 9.77592 63.249 8.81764C63.249 7.87366 63.3955 7.06555 63.6885 6.39333C63.9955 5.70679 64.3931 5.14899 64.8815 4.7199C65.3698 4.27652 65.9279 3.95471 66.5558 3.75447C67.1836 3.53993 67.8254 3.43266 68.4812 3.43266C69.2207 3.43266 69.8904 3.54708 70.4903 3.77592C71.1043 4.00477 71.6275 4.31943 72.06 4.7199C72.5065 5.12038 72.8483 5.59952 73.0855 6.15733C73.3367 6.71514 73.4622 7.323 73.4622 7.98093C73.4622 8.46722 73.3297 8.83909 73.0646 9.09654C72.7995 9.35399 72.4297 9.51847 71.9554 9.58999L66.786 10.3838C66.9394 10.8558 67.2534 11.2133 67.7278 11.4565C68.2021 11.6853 68.7463 11.7998 69.3602 11.7998C69.9323 11.7998 70.4694 11.7282 70.9717 11.5852C71.488 11.4279 71.9065 11.2491 72.2274 11.0489C72.4507 11.1919 72.639 11.3921 72.7925 11.6496C72.946 11.907 73.0227 12.1788 73.0227 12.4648C73.0227 13.1085 72.7297 13.5876 72.1437 13.9023C71.6972 14.1454 71.195 14.3099 70.6369 14.3957C70.0788 14.4815 69.5555 14.5244 69.0672 14.5244ZM68.4812 6.09297C68.1463 6.09297 67.8533 6.15018 67.6022 6.2646C67.365 6.37902 67.1697 6.5292 67.0162 6.71514C66.8627 6.88677 66.7441 7.08701 66.6604 7.31585C66.5906 7.53039 66.5488 7.75209 66.5348 7.98093L70.1136 7.38021C70.0718 7.09416 69.9183 6.8081 69.6532 6.52205C69.3881 6.236 68.9974 6.09297 68.4812 6.09297Z"
      fill="#6E3FF3"
    />
    <path
      d="M69.0672 14.5244C68.244 14.5244 67.4766 14.41 66.765 14.1812C66.0674 13.938 65.4535 13.5805 64.9233 13.1085C64.4071 12.6365 63.9955 12.0429 63.6885 11.3278C63.3955 10.6126 63.249 9.77592 63.249 8.81764C63.249 7.87366 63.3955 7.06555 63.6885 6.39333C63.9955 5.70679 64.3931 5.14899 64.8815 4.7199C65.3698 4.27652 65.9279 3.95471 66.5558 3.75447C67.1836 3.53993 67.8254 3.43266 68.4812 3.43266C69.2207 3.43266 69.8904 3.54708 70.4903 3.77592C71.1043 4.00477 71.6275 4.31943 72.06 4.7199C72.5065 5.12038 72.8483 5.59952 73.0855 6.15733C73.3367 6.71514 73.4622 7.323 73.4622 7.98093C73.4622 8.46722 73.3297 8.83909 73.0646 9.09654C72.7995 9.35399 72.4297 9.51847 71.9554 9.58999L66.786 10.3838C66.9394 10.8558 67.2534 11.2133 67.7278 11.4565C68.2021 11.6853 68.7463 11.7998 69.3602 11.7998C69.9323 11.7998 70.4694 11.7282 70.9717 11.5852C71.488 11.4279 71.9065 11.2491 72.2274 11.0489C72.4507 11.1919 72.639 11.3921 72.7925 11.6496C72.946 11.907 73.0227 12.1788 73.0227 12.4648C73.0227 13.1085 72.7297 13.5876 72.1437 13.9023C71.6972 14.1454 71.195 14.3099 70.6369 14.3957C70.0788 14.4815 69.5555 14.5244 69.0672 14.5244ZM68.4812 6.09297C68.1463 6.09297 67.8533 6.15018 67.6022 6.2646C67.365 6.37902 67.1697 6.5292 67.0162 6.71514C66.8627 6.88677 66.7441 7.08701 66.6604 7.31585C66.5906 7.53039 66.5488 7.75209 66.5348 7.98093L70.1136 7.38021C70.0718 7.09416 69.9183 6.8081 69.6532 6.52205C69.3881 6.236 68.9974 6.09297 68.4812 6.09297Z"
      fill="url(#paint5_linear_23_493)"
      fill-opacity="0.12"
    />
    <path
      d="M79.528 11.8641C79.7652 11.8641 80.0233 11.8427 80.3023 11.7998C80.5953 11.7426 80.8116 11.671 80.9511 11.5852V9.86889L79.4442 9.99762C79.0536 10.0262 78.7327 10.112 78.4815 10.2551C78.2304 10.3981 78.1048 10.6126 78.1048 10.8987C78.1048 11.1847 78.2094 11.4207 78.4187 11.6067C78.642 11.7783 79.0117 11.8641 79.528 11.8641ZM79.3605 3.43266C80.114 3.43266 80.7976 3.51132 81.4115 3.66865C82.0394 3.82598 82.5696 4.06913 83.0021 4.39809C83.4486 4.71275 83.7904 5.12038 84.0276 5.62098C84.2648 6.10727 84.3834 6.68653 84.3834 7.35876V12.1645C84.3834 12.5364 84.2788 12.8439 84.0695 13.087C83.8742 13.3159 83.637 13.5161 83.3579 13.6877C82.451 14.2455 81.1744 14.5244 79.528 14.5244C78.7885 14.5244 78.1188 14.4529 77.5188 14.3099C76.9328 14.1669 76.4235 13.9523 75.991 13.6663C75.5724 13.3802 75.2445 13.0155 75.0074 12.5721C74.7841 12.1287 74.6725 11.6138 74.6725 11.0274C74.6725 10.0405 74.9585 9.28248 75.5306 8.75328C76.1026 8.22408 76.9886 7.89511 78.1885 7.76639L80.9302 7.46603V7.31585C80.9302 6.90107 80.7488 6.60787 80.386 6.43623C80.0372 6.2503 79.528 6.15733 78.8582 6.15733C78.328 6.15733 77.8118 6.21454 77.3095 6.32896C76.8072 6.44338 76.3538 6.58641 75.9491 6.75805C75.7678 6.62932 75.6143 6.43623 75.4887 6.17878C75.3631 5.90703 75.3004 5.62813 75.3004 5.34207C75.3004 4.9702 75.3841 4.677 75.5515 4.46246C75.7329 4.23361 76.005 4.04052 76.3677 3.88319C76.7723 3.72586 77.2467 3.61144 77.7909 3.53993C78.349 3.46841 78.8722 3.43266 79.3605 3.43266Z"
      fill="#6E3FF3"
    />
    <path
      d="M79.528 11.8641C79.7652 11.8641 80.0233 11.8427 80.3023 11.7998C80.5953 11.7426 80.8116 11.671 80.9511 11.5852V9.86889L79.4442 9.99762C79.0536 10.0262 78.7327 10.112 78.4815 10.2551C78.2304 10.3981 78.1048 10.6126 78.1048 10.8987C78.1048 11.1847 78.2094 11.4207 78.4187 11.6067C78.642 11.7783 79.0117 11.8641 79.528 11.8641ZM79.3605 3.43266C80.114 3.43266 80.7976 3.51132 81.4115 3.66865C82.0394 3.82598 82.5696 4.06913 83.0021 4.39809C83.4486 4.71275 83.7904 5.12038 84.0276 5.62098C84.2648 6.10727 84.3834 6.68653 84.3834 7.35876V12.1645C84.3834 12.5364 84.2788 12.8439 84.0695 13.087C83.8742 13.3159 83.637 13.5161 83.3579 13.6877C82.451 14.2455 81.1744 14.5244 79.528 14.5244C78.7885 14.5244 78.1188 14.4529 77.5188 14.3099C76.9328 14.1669 76.4235 13.9523 75.991 13.6663C75.5724 13.3802 75.2445 13.0155 75.0074 12.5721C74.7841 12.1287 74.6725 11.6138 74.6725 11.0274C74.6725 10.0405 74.9585 9.28248 75.5306 8.75328C76.1026 8.22408 76.9886 7.89511 78.1885 7.76639L80.9302 7.46603V7.31585C80.9302 6.90107 80.7488 6.60787 80.386 6.43623C80.0372 6.2503 79.528 6.15733 78.8582 6.15733C78.328 6.15733 77.8118 6.21454 77.3095 6.32896C76.8072 6.44338 76.3538 6.58641 75.9491 6.75805C75.7678 6.62932 75.6143 6.43623 75.4887 6.17878C75.3631 5.90703 75.3004 5.62813 75.3004 5.34207C75.3004 4.9702 75.3841 4.677 75.5515 4.46246C75.7329 4.23361 76.005 4.04052 76.3677 3.88319C76.7723 3.72586 77.2467 3.61144 77.7909 3.53993C78.349 3.46841 78.8722 3.43266 79.3605 3.43266Z"
      fill="url(#paint6_linear_23_493)"
      fill-opacity="0.12"
    />
    <path
      d="M96.5 12.2932C96.4302 12.9654 96.2349 13.4803 95.914 13.8379C95.607 14.1955 95.1536 14.3743 94.5536 14.3743C94.1071 14.3743 93.7095 14.267 93.3607 14.0524C93.0119 13.8236 92.6352 13.4446 92.2305 12.9154L90.1586 10.2551V14.1597C90.0051 14.1883 89.7749 14.2241 89.468 14.267C89.175 14.3242 88.875 14.3528 88.568 14.3528C88.2611 14.3528 87.982 14.3313 87.7309 14.2884C87.4937 14.2455 87.2914 14.1597 87.1239 14.031C86.9565 13.9023 86.824 13.7306 86.7263 13.5161C86.6426 13.2872 86.6007 12.994 86.6007 12.6365V0.214541C86.7542 0.171632 86.9774 0.128724 87.2704 0.0858166C87.5774 0.0286055 87.8843 0 88.1913 0C88.4983 0 88.7703 0.0214538 89.0075 0.0643615C89.2587 0.10727 89.4679 0.193087 89.6354 0.321811C89.8028 0.450536 89.9284 0.62932 90.0121 0.858164C90.1098 1.07271 90.1586 1.35876 90.1586 1.71633V7.38021L93.7583 3.60429C94.4839 3.60429 95.0629 3.76877 95.4954 4.09773C95.9419 4.4267 96.1651 4.84148 96.1651 5.34207C96.1651 5.55661 96.1303 5.75685 96.0605 5.94279C95.9907 6.11442 95.8791 6.29321 95.7256 6.47914C95.5861 6.66508 95.3978 6.86532 95.1606 7.07986C94.9373 7.2801 94.6722 7.51609 94.3653 7.78784L93.1514 8.86055L96.5 12.2932Z"
      fill="#6E3FF3"
    />
    <path
      d="M96.5 12.2932C96.4302 12.9654 96.2349 13.4803 95.914 13.8379C95.607 14.1955 95.1536 14.3743 94.5536 14.3743C94.1071 14.3743 93.7095 14.267 93.3607 14.0524C93.0119 13.8236 92.6352 13.4446 92.2305 12.9154L90.1586 10.2551V14.1597C90.0051 14.1883 89.7749 14.2241 89.468 14.267C89.175 14.3242 88.875 14.3528 88.568 14.3528C88.2611 14.3528 87.982 14.3313 87.7309 14.2884C87.4937 14.2455 87.2914 14.1597 87.1239 14.031C86.9565 13.9023 86.824 13.7306 86.7263 13.5161C86.6426 13.2872 86.6007 12.994 86.6007 12.6365V0.214541C86.7542 0.171632 86.9774 0.128724 87.2704 0.0858166C87.5774 0.0286055 87.8843 0 88.1913 0C88.4983 0 88.7703 0.0214538 89.0075 0.0643615C89.2587 0.10727 89.4679 0.193087 89.6354 0.321811C89.8028 0.450536 89.9284 0.62932 90.0121 0.858164C90.1098 1.07271 90.1586 1.35876 90.1586 1.71633V7.38021L93.7583 3.60429C94.4839 3.60429 95.0629 3.76877 95.4954 4.09773C95.9419 4.4267 96.1651 4.84148 96.1651 5.34207C96.1651 5.55661 96.1303 5.75685 96.0605 5.94279C95.9907 6.11442 95.8791 6.29321 95.7256 6.47914C95.5861 6.66508 95.3978 6.86532 95.1606 7.07986C94.9373 7.2801 94.6722 7.51609 94.3653 7.78784L93.1514 8.86055L96.5 12.2932Z"
      fill="url(#paint7_linear_23_493)"
      fill-opacity="0.12"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_23_493"
        x1="48.5"
        y1="0"
        x2="48.5"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
