import React, { useEffect, useState, useCallback } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { StepIndicator } from "@/components/step-indicator";
import { AvatarGroup } from "@/components/avatar-group";
import { CustomInput } from "./custom-input";
import { ProfileCard, ProfileCardSkeleton } from "./profile-card";
import { useSnapshot } from "valtio";
import state, { Steps } from "@/store/state";
import actions from "@/store/actions";
import { CustomAlert } from "./custom-alert";
import { useSearchParams } from "react-router-dom";
import { trackAccountSelection } from "@/utils/analytics";
import { motion } from "framer-motion";

interface SelectAccountProps {
  onContinue: () => void;
}

export function SelectAccount({ onContinue }: SelectAccountProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isProfileSelected, setIsProfileSelected] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const debouncedSearchQuery = useDebounce(searchQuery, 1500);
  const { user, isLoading, error } = useSnapshot(state);
  const [searchParams] = useSearchParams();
  const [isInitialSearch, setIsInitialSearch] = useState(true);

  useEffect(() => {
    actions.initializeData();
    const usernameParam = searchParams.get("username");
    if (usernameParam) {
      const cleanedUsername = cleanSearchQuery(usernameParam);
      actions.resetAllState();
      setSearchQuery(cleanedUsername);
      searchUser(cleanedUsername);
    }
    setIsInitialSearch(false);
  }, []);

  const cleanSearchQuery = (query: string): string => {
    return query
      .replace(/https:\/\/(www\.)?tiktok\.com\//g, "")
      .replace(/@/g, "")
      .trim();
  };

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | string) => {
      const newValue = typeof event === "string" ? event : event.target.value;
      setSearchQuery(cleanSearchQuery(newValue));
    },
    []
  );

  const handleClear = useCallback(() => {
    setSearchQuery("");
    setIsProfileSelected(false);
    actions.resetAllState();
  }, []);

  useEffect(() => {
    if (!isInitialSearch && debouncedSearchQuery.length > 2) {
      setIsProfileSelected(false);
      searchUser(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, isInitialSearch]);

  const searchUser = async (query: string) => {
    setIsProfileSelected(false);
    setRetryCount(0);

    try {
      await actions.searchTikTokUser(query);
      setIsProfileSelected(true);
      trackAccountSelection(); // Burada trackAccountSelection'ı çağırıyoruz
    } catch (error) {
      console.error("Error searching user:", error);
      setIsProfileSelected(false);
      actions.resetUser();
      if (error instanceof Error && error.message.includes("Retrying")) {
        setRetryCount((prev) => prev + 1);
        if (retryCount < 5) {
          setTimeout(() => searchUser(query), 500);
        }
      }
    }
  };

  const handleProfileSelect = useCallback((selected: boolean) => {
    setIsProfileSelected(selected);
  }, []);

  const isPlatformiOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  useEffect(() => {
    if (error && isPlatformiOS()) {
      const input = document.querySelector('input[name="tiktok-username"]');
      if (input) {
        (input as HTMLInputElement).focus();
        (input as HTMLInputElement).click();
      }
    }
  }, [error]);

  const placeholderVariants = {
    normal: { scale: 1 },
    attention: { scale: [1, 1.1, 1], transition: { repeat: 2, duration: 0.7 } },
  };

  const handleContinue = () => {
    actions.updateCurrentStep(Steps.CHOOSE_SERVICE);
    onContinue();
  };

  return (
    <>
      <div className="p-6 flex flex-col items-center gap-4 bg-gray-50 border-t border-b border-gray-100 h-full overflow-y-auto z-10">
        <div className="flex justify-center w-full">
          <Card
            className="p-5 rounded-2xl border border-[#F4F4F5] relative bg-white shadow-[0px_1px_2px_0px_rgba(228,229,231,0.24)] min-w-[327px] min-h-[280px] max-w-full w-full z-10"
            style={{
              backgroundImage: "url(/frame1.png), url(/frame2.png)",
              backgroundPosition:
                "top -50px right -100px, bottom -90px left -70px",
              backgroundRepeat: "no-repeat, no-repeat",
            }}
          >
            <div className="flex justify-center mb-4">
              <img src="/logo.png" alt="Famepeak" className="w-9 h-9" />
            </div>
            <h2 className="text-center mb-2">
              <span className="text-purple-300 text-body-xl-m">Grow Your</span>{" "}
              <span className="text-gray-900 text-body-xl-r">
                Tiktok Account
              </span>
            </h2>
            <p className="text-center text-gray-400 text-body-xs-r mb-4">
              Join thousands of satisfied creators and <br />
              experience rapid growth with us.
            </p>
            <div className="flex justify-center mb-5">
              <AvatarGroup />
            </div>
            <div className="relative">
              <motion.div
                animate={{ scale: [1, 1.05, 1] }}
                transition={{ repeat: 3, duration: 0.7 }}
              >
                <CustomInput
                  placeholder="type your tiktok username"
                  value={searchQuery}
                  onChange={handleSearch}
                  onClear={handleClear}
                  loading={isLoading}
                  error={!!error}
                  name="tiktok-username"
                />
              </motion.div>
            </div>
          </Card>
        </div>

        {error && (
          <CustomAlert variant="red" title="Oops!" description={error} />
        )}
        {!isLoading && !error && user && (
          <ProfileCard
            username={user.username}
            name={user.name}
            followers={user.totalFollower.toString()}
            likes={user.totalLike.toString()}
            avatarUrl={user.profilePicture}
            isVerified={true}
            isSelected={true}
            onSelect={handleProfileSelect}
          />
        )}
        {isLoading && <ProfileCardSkeleton />}

        {/* Guarantee section - always visible and full width */}
        <div className="mt-1 w-full">
          <div className="bg-white rounded-lg shadow-sm p-4 flex items-start gap-4">
            <motion.div
              animate={{ scale: [1, 1.05, 1] }}
              transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
              className="flex-shrink-0"
            >
              <img
                src="https://dyo1uzh2defwk.cloudfront.net/img/guarantee.webp"
                alt="Guarantee"
                className="w-20 h-20 sm:w-22 sm:h-22 md:w-24 md:h-24 lg:w-20 lg:h-20 object-contain"
              />
            </motion.div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                10-day money-back guarantee
              </h3>
              <p className="text-sm text-gray-600">
                If you're not seeing significant follower and engagement growth
                within 10 days, we'll give you a full refund, no questions
                asked.
              </p>
            </div>
          </div>
        </div>
      </div>
      {user && (
        <div className="py-3 px-4 fixed bottom-0 left-0 right-0 max-w-2xl bg-white mx-auto">
          <Button
            onClick={handleContinue}
            disabled={isLoading || !!error}
            className="w-full"
          >
            Continue
          </Button>
        </div>
      )}
    </>
  );
}
