import React, { useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import Icon from "@/components/icon";
import { cn } from "@/lib/utils";
import { Skeleton } from "./ui/skeleton";

const formatNumber = new Intl.NumberFormat("en-US", {
  notation: "compact",
  maximumFractionDigits: 1,
}).format;

export const VerifiedBadge = ({ className }: { className?: string }) => (
  <svg
    className={cn("absolute -top-1 -right-2 h-6 w-6", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g filter="url(#filter0_d_23_3509)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1715 3.75741L12.0606 2.64649C11.4748 2.06071 10.5251 2.06071 9.93927 2.64649L8.82835 3.75741H7.25728C6.42886 3.75741 5.75728 4.42898 5.75728 5.25741V6.82847L4.64637 7.93939C4.06059 8.52517 4.06058 9.47492 4.64637 10.0607L5.75728 11.1716V12.7427C5.75728 13.5711 6.42886 14.2427 7.25728 14.2427H8.82835L9.93927 15.3536C10.5251 15.9394 11.4748 15.9394 12.0606 15.3536L13.1715 14.2427H14.7426C15.571 14.2427 16.2426 13.5711 16.2426 12.7427V11.1716L17.3535 10.0607C17.9393 9.47492 17.9393 8.52517 17.3535 7.93939L16.2426 6.82847V5.25741C16.2426 4.42898 15.571 3.75741 14.7426 3.75741H13.1715ZM12.6108 4.61096C12.7046 4.70473 12.8318 4.75741 12.9644 4.75741H14.7426C15.0187 4.75741 15.2426 4.98126 15.2426 5.25741V7.03558C15.2426 7.16819 15.2952 7.29537 15.389 7.38913L16.6464 8.64649C16.8416 8.84176 16.8416 9.15834 16.6464 9.3536L15.389 10.611C15.2952 10.7047 15.2426 10.8319 15.2426 10.9645V12.7427C15.2426 13.0188 15.0187 13.2427 14.7426 13.2427H12.9644C12.8318 13.2427 12.7046 13.2954 12.6108 13.3891L11.3535 14.6465C11.1582 14.8418 10.8416 14.8418 10.6464 14.6465L9.38901 13.3891C9.29524 13.2954 9.16807 13.2427 9.03546 13.2427H7.25728C6.98114 13.2427 6.75728 13.0188 6.75728 12.7427V10.9645C6.75728 10.8319 6.70461 10.7047 6.61084 10.611L5.35348 9.3536C5.15822 9.15834 5.15822 8.84176 5.35348 8.64649L6.61084 7.38913C6.70461 7.29537 6.75728 7.16819 6.75728 7.03558V5.25741C6.75728 4.98126 6.98114 4.75741 7.25728 4.75741H9.03546C9.16807 4.75741 9.29524 4.70473 9.38901 4.61096L10.6464 3.3536C10.8416 3.15834 11.1582 3.15834 11.3535 3.3536L12.6108 4.61096Z"
        fill="white"
      />
      <path
        d="M12.9644 4.75728C12.8318 4.75728 12.7046 4.70461 12.6108 4.61084L11.3535 3.35348C11.1582 3.15822 10.8416 3.15822 10.6464 3.35348L9.38901 4.61084C9.29524 4.70461 9.16807 4.75728 9.03546 4.75728H7.25728C6.98114 4.75728 6.75728 4.98114 6.75728 5.25728V7.03546C6.75728 7.16807 6.70461 7.29524 6.61084 7.38901L5.35348 8.64637C5.15822 8.84163 5.15822 9.15822 5.35348 9.35348L6.61084 10.6108C6.70461 10.7046 6.75728 10.8318 6.75728 10.9644V12.7426C6.75728 13.0187 6.98114 13.2426 7.25728 13.2426H9.03546C9.16807 13.2426 9.29524 13.2952 9.38901 13.389L10.6464 14.6464C10.8416 14.8416 11.1582 14.8416 11.3535 14.6464L12.6108 13.389C12.7046 13.2952 12.8318 13.2426 12.9644 13.2426H14.7426C15.0187 13.2426 15.2426 13.0187 15.2426 12.7426V10.9644C15.2426 10.8318 15.2952 10.7046 15.389 10.6108L16.6464 9.35348C16.8416 9.15822 16.8416 8.84163 16.6464 8.64637L15.389 7.38901C15.2952 7.29524 15.2426 7.16807 15.2426 7.03546V5.25728C15.2426 4.98114 15.0187 4.75728 14.7426 4.75728H12.9644Z"
        fill="#35B9E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6869 7.68697L10.3334 11.0405L8.64648 9.35363L9.35359 8.64652L10.3334 9.6263L12.9798 6.97986L13.6869 7.68697Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_23_3509"
        x="-0.555556"
        y="-0.777778"
        width="23.1111"
        height="23.1111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.77778" />
        <feGaussianBlur stdDeviation="1.77778" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.113725 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_23_3509"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_23_3509"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

interface ProfileCardProps {
  username: string;
  name: string;
  followers: string;
  likes: string;
  avatarUrl: string;
  isSelected: boolean;
  onSelect: (selected: boolean) => void;
  isVerified?: boolean;
}

export function ProfileCard({
  username,
  name,
  followers,
  likes,
  avatarUrl,
  isSelected,
  onSelect,
  isVerified = false,
}: ProfileCardProps) {
  const handleSelect = () => {
    onSelect(!isSelected);
  };

  const parseNumber = (value: number | string): number => {
    if (typeof value === "string") {
      return parseInt(value.replace(/,/g, ""), 10);
    }
    return value;
  };

  return (
    <div
      className={cn(
        "flex min-w-[327px] w-full max-h-[60px] max-w-full items-center gap-3 rounded-[10px] border border-[#F4F4F5] bg-white p-[10px] shadow-[0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_46px_13px_0px_rgba(0,0,0,0.00)_inset,0px_29px_12px_0px_rgba(0,0,0,0.00)_inset,0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset]",
        isSelected &&
          "border-[#6E3FF3] shadow-[0px_0px_0px_3px_#FFF,0px_0px_0px_4px_#E4E5E7]"
      )}
      // onClick={handleSelect}
    >
      <div className="relative">
        <Avatar className="h-10 w-10">
          <AvatarImage src={avatarUrl} alt={name} />
          <AvatarFallback>{name.charAt(0)}</AvatarFallback>
        </Avatar>
        {isVerified && <VerifiedBadge />}
      </div>
      <div className="flex-grow">
        <div className="flex items-center">
          <span className="text-body-xs-m">{username}</span>
        </div>
        <div className="text-detail text-gray-400">{name}</div>
      </div>
      <div className="w-[66px]">
        <div className="text-body-xs-m">
          {formatNumber(parseNumber(followers))}
        </div>
        <div className="text-detail text-gray-400">Followers</div>
      </div>
      <div className="w-[66px]">
        <div className="text-body-xs-m">{formatNumber(parseNumber(likes))}</div>
        <div className="text-detail text-gray-400">Likes</div>
      </div>
      <Icon
        icon={
          isSelected ? "checkbox-circle-fill" : "checkbox-blank-circle-line"
        }
        className={cn(
          "h-5 w-5 ml-auto",
          isSelected ? "text-[#6E3FF3]" : "text-gray-400"
        )}
      />
    </div>
  );
}

export function ProfileCardSkeleton() {
  return (
    <div
      className={cn(
        "flex min-w-[327px] w-full max-h-[60px] justify-between max-w-full items-center gap-3 rounded-[10px] border border-[#F4F4F5] bg-white p-[10px] shadow-[0px_16px_11px_-8px_rgba(228,229,231,0.52),0px_46px_13px_0px_rgba(0,0,0,0.00)_inset,0px_29px_12px_0px_rgba(0,0,0,0.00)_inset,0px_17px_10px_0px_rgba(0,0,0,0.01)_inset,0px_7px_7px_0px_rgba(0,0,0,0.00)_inset,0px_2px_4px_0px_rgba(0,0,0,0.01)_inset]"
      )}
    >
      <div className="relative">
        <Avatar className="h-10 w-10">
          <AvatarFallback>
            <Skeleton className="h-full w-full rounded-full" />
          </AvatarFallback>
        </Avatar>
        <VerifiedBadge />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-3 w-16 mb-1 rounded-full bg-[#E4E4E7]" />
        <Skeleton className="h-2 w-12 rounded-full bg-[#F4F4F5]" />
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-3 w-16 mb-1 rounded-full bg-[#E4E4E7]" />
        <Skeleton className="h-2 w-16 rounded-full bg-[#F4F4F5]" />
      </div>
      <div className="text-right flex flex-col gap-2">
        <Skeleton className="h-3 w-16 mb-1 rounded-full bg-[#E4E4E7]" />
        <Skeleton className="h-2 w-16 rounded-full bg-[#F4F4F5]" />
      </div>
      <Skeleton className="h-3 w-3 rounded-full bg-gray-200" />
    </div>
  );
}
