import React from "react";
import { Separator } from "@/components/ui/separator";
import Icon from "./icon";
import { FeedItem } from "@/store/state";
import { cn } from "@/lib/utils";
import actions from "@/store/actions";

const formatter = new Intl.NumberFormat("en", {
  notation: "compact",
  compactDisplay: "short",
});

export function UserPostCard({
  id,
  videoCover,
  videoDesc,
  playCount,
  diggCount,
  shareCount,
  commentCount,
  isSelected,
}: FeedItem) {
  const handleClick = () => {
    actions.togglePostSelection(id);
  };

  return (
    <div
      className={cn(
        "relative overflow-hidden min-w-[104px] min-h-[184px] cursor-pointer rounded-[8px] border",
        isSelected
          ? "border-[#F4F4F5] shadow-[0px_0px_0px_1px_#FFF,0px_0px_0px_2.4px_#784CF4]"
          : "border-transparent"
      )}
      onClick={handleClick}
    >
      <div
        className="min-w-[104px] h-[184px] md:w-full md:h-full bg-black"
        style={{
          background: `linear-gradient(0deg, rgba(24, 24, 27, ${
            isSelected ? "0.40" : "0.12"
          }) 0%, rgba(24, 24, 27, ${
            isSelected ? "0.40" : "0.12"
          }) 100%), url(${videoCover}) black center / contain no-repeat`,
        }}
      />
      <div className="absolute top-[3px] right-[3px] rounded-[6px] flex justify-center items-center w-6 h-6 border-[0.5px] border-white/20 bg-white/20 backdrop-blur-[1px]">
        <Icon
          icon={
            isSelected ? "checkbox-circle-fill" : "checkbox-blank-circle-line"
          }
          className={cn("w-4 h-4", isSelected ? "text-white" : "text-white")}
        />
      </div>
      <div className="max-w-[98px] md:max-w-[150px] absolute bottom-1 left-1/2 transform -translate-x-1/2 flex justify-between items-center text-white w-full px-[6px] py-[2px] min-h-[32px] text-detail rounded-[6px] border border-white/20 bg-white/8 backdrop-blur-[2px]">
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center flex-col">
            <Icon icon="heart-3-fill" className="w-4 h-4" />
            <span>{formatter.format(diggCount)}</span>
          </div>
          <Separator
            orientation="vertical"
            className="h-3 !bg-[rgba(255,255,255,0.22)]"
          />
          <div className="flex items-center flex-col">
            <Icon icon="eye-fill" className="w-4 h-4" />
            <span>{formatter.format(playCount)}</span>
          </div>
          <Separator
            orientation="vertical"
            className="h-3 !bg-[rgba(255,255,255,0.22)]"
          />
          <div className="flex items-center flex-col">
            <Icon icon="share-fill" className="w-4 h-4" />
            <span>{formatter.format(shareCount)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
