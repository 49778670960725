import React, { useState, useEffect, useRef } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
} from "@/components/ui/drawer";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import Icon from "./icon";
import { useIsMobile } from "@/hooks/useIsMobile";
import { cn } from "@/lib/utils";
import { useSnapshot } from "valtio";
import state, { Steps } from "@/store/state";
import { Card } from "@/components/ui/card";

interface FAQDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

type FAQCategory =
  | "Overview"
  | "Service Details"
  | "Payment and Ordering"
  | "Order Support";

const faqCategories: FAQCategory[] = [
  "Overview",
  "Service Details",
  "Payment and Ordering",
  "Order Support",
];

const faqItems: Record<
  FAQCategory,
  Array<{ question: string; answer: string }>
> = {
  Overview: [
    {
      question: "What is Famepeak?",
      answer: "Famepeak is a specialized service designed to help grow your presence on TikTok™ by boosting engagement across your posts. Whether you're looking for more followers, likes, views, or shares, Famepeak delivers consistent results, supporting you in reaching a wider audience.",
    },
    {
      question: "Are your services guaranteed?",
      answer: "Yes, we offer a service delivery guarantee as well as a 10-day money-back guarantee. We ensure all services in your order are delivered as promised.\n\nIf we fail to deliver your order, you're entitled to a full refund under our 10-day Money Back Guarantee.",
    },
    {
      question: "Is it safe to use your social media boosting services?",
      answer: "Absolutely! We use only proven methods to grow followers, likes, views, and shares, ensuring they come from authentic, active accounts. This way, your account remains safe from any harmful or spammy practices.\n\nAs a result, none of our clients have ever reported issues with their accounts. We provide safe and effective services to thousands of clients every day.",
    },
    {
      question: "Will my account be flagged or banned for using this service?",
      answer: "No, your account will not be flagged or banned. We use high-quality, established accounts to provide authentic engagement, including followers, shares, likes, and views. These accounts meet specific activity and reliability standards to ensure your account remains safe and free from penalties.",
    },
    {
      question: "Do I need to share my account details to use your services?",
      answer: "No, not at all! We never ask for your password or any sensitive account details to provide our services.",
    },
    {
      question: "How do I place an order for your services?",
      answer: "To place an order, first search for your username and select your account. Then, choose the engagement type(s) you want and select the target posts for the engagement. Complete the payment, and you'll start enjoying the speed boost on your account within hours of your order.",
    },
    {
      question: "How will your services help enhance my authenticity and presence on the platform?",
      answer: "A solid follower base and consistent engagement increase your profile's appeal and visibility. Our services help you attract real followers, likes, views, and shares, allowing you to build a credible and respected presence on TikTok™. This steady boost in activity enables you to create an authentic and engaging profile that resonates with new audiences.",
    },
    {
      question: "Will purchasing engagement improve my organic visibility on the platform?",
      answer: "Yes, purchasing engagement can help boost your organic visibility. The TikTok™ algorithm tends to promote content with high engagement, as it signals community interest. By increasing your followers, likes, views, and shares through our services, you improve your content's potential to reach a broader audience.",
    },
  ],
  "Service Details": [
    {
      question: "How exactly do I benefit from your engagement services?",
      answer: "Our engagement services quickly boost your online presence, enhancing your credibility and attracting more users to your profile. This helps grow your audience and increase visibility, essential for influencers, businesses, or anyone looking to expand their reach.",
    },
    {
      question: "Can I purchase multiple services in one order?",
      answer: "Yes, our website allows you to select and add multiple services to your basket before completing your purchase. This means you can order different services for different posts all at once.",
    },
    {
      question: "How fast do you deliver the services?",
      answer: "We start processing each order within a few hours of placement. For smaller packages, the complete service is typically delivered the same day.\n\nLarger packages may take a bit longer to complete since we use a drip-feed method that gradually distributes the service over time. This approach ensures the growth appears natural, helping protect your account from TikTok's algorithm.\n\nIf you don't notice any activity within 24 hours of your purchase, please reach out to our customer support team, and we'll be happy to assist you.",
    },
    {
      question: "How will I know if your services are working?",
      answer: "You will start to see an increase in your followers, likes, shares, and views within a few hours of placing your order. We also provide regular progress updates and are available to address any questions or concerns you may have.",
    },
    {
      question: "Will I experience any drop in the engagement I purchased?",
      answer: "We use high-quality sources for our engagement services, making drops highly unlikely after delivering the service you purchased. Our automated systems monitor and refill any drops that occur.\n\nHowever, if you notice an unexpected significant drop, please contact our customer support team via email with your order details.",
    },
    {
      question: "Is there a limit I should be aware of when boosting my account?",
      answer: "Technically, there's no limit to the number of followers, likes, views, or shares you can add to your account. For the best results, we recommend a gradual increase in engagement to keep your growth looking natural and balanced. Famepeak allows you to enhance your profile steadily, supporting authentic and lasting visibility on the platform.",
    },
  ],
  "Payment and Ordering": [
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit and debit cards, including Visa, Mastercard, Amex, Union Pay, and Amazon. Your transactions are always encrypted to ensure a secure payment process.",
    },
    {
      question: "Are payments on your website secure?",
      answer: "Yes, we use a leading payment processor that employs advanced security protocols, ensuring that all transactions on our website are completely secure.",
    },
    {
      question: "Will you send me a receipt for my order?",
      answer: "Yes, once your order is placed, our system will automatically approve it and send a receipt for your purchase to your email address.",
    },
    {
      question: "Is it possible to cancel my order after it starts delivering?",
      answer: "No, once your order begins and engagement delivery is underway, it cannot be canceled or stopped.",
    },
  ],
  "Order Support": [
    {
      question: "Is there anything I need to do after placing my order?",
      answer: "Yes, keep your social media account public and avoid changing your username after placing the order to ensure your service is delivered.\n\nNot adhering to these instructions will prevent us from delivering the service.",
    },
    {
      question: "How do I track the status of my order?",
      answer: "You will receive a confirmation email after placing your order. To track your order in real time, simply use the link provided in this email.",
    },
    {
      question: "Why isn't my order delivered yet?",
      answer: "Order processing may take up to 24 hours. Our dedicated team monitors order statuses closely and resolves any issues promptly.\n\nIf your service delivery hasn't started within 24 hours, please contact our support team via email.",
    },
    {
      question: "How can I track my order?",
      answer: "You can track your order status through our order tracking system. We'll also send you email updates about your order progress.",
    },
    {
      question: "Is it possible to get a refund for my order?",
      answer: "Yes, you can request a refund if there was a failure in service delivery or if you are dissatisfied with our services.\n\nTo request a refund, please contact our customer service team via email, including the details of your order and the reason why you would like a refund. We will review your case and process your request accordingly.",
    },
  ],
};

export function FAQDrawer({ open, onOpenChange }: FAQDrawerProps) {
  const isMobile = useIsMobile();
  const store = useSnapshot(state);
  const [selectedCategory, setSelectedCategory] =
    useState<FAQCategory>("Overview");
  const [defaultAccordionValue, setDefaultAccordionValue] =
    useState<string>("item-0");
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollToCategory = (category: FAQCategory) => {
    requestAnimationFrame(() => {
      const button = document.querySelector(
        `[data-category="${category}"]`
      ) as HTMLElement;
      const container = scrollContainerRef.current;

      if (button && container) {
        const containerWidth = container.offsetWidth;
        const buttonLeft = button.offsetLeft;
        const buttonWidth = button.offsetWidth;

        // Butonun merkezi container'ın merkezinde olacak şekilde scroll pozisyonunu hesapla
        const targetScroll = buttonLeft - containerWidth / 2 + buttonWidth / 2;

        container.scrollTo({
          left: targetScroll,
          behavior: "smooth",
        });
      }
    });
  };

  const handleCategoryClick = (category: FAQCategory) => {
    setSelectedCategory(category);
    setDefaultAccordionValue("item-0");
    scrollToCategory(category);
  };

  useEffect(() => {
    if (open) {
      let initialCategory: FAQCategory = "Overview";

      if (window.location.pathname.includes("orderStatus")) {
        initialCategory = "Order Support";
      } else {
        switch (store.currentStep) {
          case Steps.SELECT_ACCOUNT:
            initialCategory = "Overview";
            break;
          case Steps.CHOOSE_SERVICE:
            initialCategory =
              store.serviceSubStep === "BASKET"
                ? "Payment and Ordering"
                : "Service Details";
            break;
          case Steps.CHECKOUT:
            initialCategory = "Payment and Ordering";
            break;
        }
      }

      setSelectedCategory(initialCategory);
      setDefaultAccordionValue("item-0");

      // Drawer açıldıktan sonra scroll işlemini gerçekleştir
      setTimeout(() => {
        scrollToCategory(initialCategory);
      }, 100);
    }
  }, [open, store.currentStep]);

  return (
    <Drawer
      open={open}
      onOpenChange={onOpenChange}
      shouldScaleBackground
      setBackgroundColorOnScale={false}
    >
      <DrawerContent
        className={cn(isMobile ? "h-[95%]" : "h-[85%]", "max-w-2xl mx-auto")}
      >
        <DrawerHeader className="flex flex-col items-center px-4 pb-0">
          <div className="w-full flex items-baseline mb-4">
            <div
              className="border w-7 h-7 border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
              onClick={() => onOpenChange(false)}
            >
              <Icon icon="arrow-left-line" className="w-5 h-5 text-gray-900" />
            </div>
            <div className="flex-grow flex justify-center flex-col items-center">
              <div className="w-10 h-10 mb-3 rounded-full bg-turquoise-300 flex items-center justify-center shadow-[0px_0px_0px_2.5px_var(--Turquoise-100,#EBFAFF)] border-[2.5px] border-[rgba(255,255,255,0.56)] backdrop-blur-[1.25px]">
                <Icon icon="question-fill" className="text-white h-6 w-6" />
              </div>
              <DrawerTitle className="text-center !text-body-xs-m text-gray-900 mb-1">
                Frequently Asked Questions
              </DrawerTitle>
              <DrawerDescription className="text-center !text-body-2xs-r text-gray-400">
                Find answers to common questions about our services
              </DrawerDescription>
            </div>
            <div className="w-7" /> {/* Spacing for alignment */}
          </div>

          <div className="w-full relative">
            <div
              ref={scrollContainerRef}
              className="flex space-x-2 px-4 pl-0 py-2 overflow-x-auto scrollbar-hide scroll-smooth mb-2"
            >
              {faqCategories.map((category) => (
                <button
                  key={category}
                  data-category={category}
                  onClick={() => handleCategoryClick(category)}
                  className={cn(
                    "px-2 py-[6px] rounded-[32px] !text-body-2xs-m border transition-colors shrink-0",
                    selectedCategory === category
                      ? "bg-green-300 text-white border-white"
                      : "bg-gray-100 text-gray-900 border-white hover:bg-gray-100"
                  )}
                  style={{
                    boxShadow:
                      selectedCategory === category
                        ? "0px 0px 0px 1px var(--Green-100, #EFFAF6)"
                        : "0px 0px 0px 1px var(--Gray-100, #F4F4F5)",
                  }}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </DrawerHeader>

        <div className="flex-1 px-4 overflow-y-auto">
          <Accordion
            type="single"
            collapsible
            key={selectedCategory}
            defaultValue={defaultAccordionValue}
            className="w-full space-y-3"
          >
            {faqItems[selectedCategory].map((item, index) => (
              <Card
                key={index}
                className="border border-gray-100 bg-gray-50 rounded-[8px]"
              >
                <AccordionItem value={`item-${index}`} className="border-0">
                  <AccordionTrigger
                    iconClassName="rounded-[4px] border border-gray-100 text-gray-900"
                    className="!text-body-2xs-m text-left text-gray-900 hover:no-underline p-3 pb-[12px]"
                  >
                    {item.question}
                  </AccordionTrigger>
                  <AccordionContent className="!text-body-2xs-r text-gray-400 px-3 pt-0">
                    {item.answer}
                  </AccordionContent>
                </AccordionItem>
              </Card>
            ))}
          </Accordion>
        </div>

        <DrawerFooter className="px-4 py-3 border-t border-gray-100">
          <Button
            onClick={() => onOpenChange(false)}
            className="w-full !bg-black flex items-center"
          >
            Continue Shopping
            <span className="ml-1">👉</span>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
