import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { trackPageView } from "@/utils/analytics";
import Icon from "../icon";

interface PaymentFailedAlertProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCancel: () => void;
  errorMessage: string;
}

const PaymentFailedAlert: React.FC<PaymentFailedAlertProps> = ({
  open,
  onOpenChange,
  onCancel,
  errorMessage,
}) => {
  React.useEffect(() => {
    if (open) {
      trackPageView("Failed Payment");
    }
  }, [open]);

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="max-w-[327px] rounded-[12px] px-4 py-5 gap-1">
        <AlertDialogHeader className="space-y-1">
          <div className="flex justify-center mb-2">
            <div
              className="w-10 h-10 rounded-full flex items-center justify-center"
              style={{
                background: "var(--Red-300, #DF1C41)",
                border: "2.5px solid rgba(255, 255, 255, 0.56)",
                boxShadow: "0px 0px 0px 2.5px var(--Red-100, #FDEDF0)",
                backdropFilter: "blur(1.25px)",
              }}
            >
              <Icon
                icon="error-warning-fill"
                className="w-[30px] h-[30px] text-white"
              />
            </div>
          </div>
          <AlertDialogTitle className="!text-body-sm-m text-gray-900 text-center">
            Payment Failed
          </AlertDialogTitle>
          <AlertDialogDescription className="!text-body-2xs-r text-gray-400 text-center">
            {errorMessage ||
              "Your credit card could not be processed. Please check your card details and try again or contact your bank for assistance."}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex flex-col justify-center items-center mt-4">
          <AlertDialogCancel
            onClick={onCancel}
            className="mt-0 w-full max-h-8 bg-gray-50 border-gray-100 text-body-xs-m hover:bg-gray-100 focus:bg-gray-100"
          >
            Okay
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default PaymentFailedAlert;
