import { ColorVariant, CustomAlert } from "@/components/custom-alert";
import React from "react";
import { useState, useCallback } from "react";
import toast, { ToastPosition } from "react-hot-toast";

interface NotifyOptions {
  position?:
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";
  duration?: number;
  variant?: ColorVariant;
  icon?: string;
}

const defaultOptions: NotifyOptions = {
  position: "bottom-center",
  duration: 2000,
  variant: "green",
};

export function useNotify() {
  const notify = useCallback(
    (title: string, description: string, options?: NotifyOptions) => {
      const { position, duration, variant, icon } = {
        ...defaultOptions,
        ...options,
      };

      toast(
        (t) => (
          <div onClick={() => toast.dismiss(t.id)}>
            <CustomAlert
              variant={variant!}
              title={title}
              icon={icon}
              description={description}
            />
          </div>
        ),
        {
          position: position as ToastPosition,
          duration,
        }
      );
    },
    []
  );

  return { notify };
}
