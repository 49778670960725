import React, { useState, useEffect, useCallback } from "react";
import { cn } from "@/lib/utils";
import Icon from "./icon";
import { Separator } from "./ui/separator";
import { VerifiedBadge } from "./profile-card";

interface ValueCard {
  type: "value";
  title: string;
  description: string;
  icon: string;
}

interface TestimonialCard {
  type: "testimonial";
  name: string;
  title: string;
  description: string;
  avatarVariant: number; // 1-6 for different avatar variants
}

type Card = ValueCard | TestimonialCard;

interface PromiseCarouselProps {
  className?: string;
}

interface StarRatingProps {
  rating: number;
  maxStars?: number;
  className?: string;
}

export function StarRating({
  rating,
  maxStars = 5,
  className,
}: StarRatingProps) {
  return (
    <div className="flex gap-[2px]">
      {[...Array(maxStars)].map((_, index) => (
        <Icon
          key={index}
          icon="star-s-fill"
          className={`w-4 h-4 ${
            index < rating ? "text-yellow-300" : "text-gray-200"
          }`}
        />
      ))}
    </div>
  );
}

// Add this type and style mapping before the VALUES array
interface IconStyle {
  boxShadow: string;
  background: string;
}

const ICON_STYLES: Record<string, IconStyle> = {
  "shield-dollar-line": {
    boxShadow: "0px 0px 0px 2.5px var(--Orange-100, #FEF3EB)",
    background: "var(--Orange-300, #F17B2C)",
  },
  "star-line": {
    boxShadow: "0px 0px 0px 2.5px var(--Purple-100, #EEEBFF)",
    background: "var(--Purple-300, #6E3FF3)",
  },
  "service-line": {
    boxShadow: "0px 0px 0px 2.5px var(--Blue-100, #EBF1FF)",
    background: "var(--Blue-300, #375DFB)",
  },
  "flashlight-line": {
    boxShadow: "0px 0px 0px 2.5px var(--Yellow-100, #FEF7EC)",
    background: "var(--Yellow-300, #F2AE40)",
  },
  "customer-service-2-line": {
    boxShadow: "0px 0px 0px 2.5px var(--Turquoise-100, #EBFAFF)",
    background: "var(--Turquoise-300, #35B9E9)",
  },
  "price-tag-3-line": {
    boxShadow: "0px 0px 0px 2.5px var(--Green-100, #EFFAF6)",
    background: "var(--Green-300, #38C793)",
  },
};

const VALUES: ValueCard[] = [
  {
    type: "value",
    title: "10-Day Guarantee",
    description: `Enjoy risk-free growth with our 10-day guarantee. Get a full refund if promised results aren't met—no questions asked!`,
    icon: "shield-dollar-line",
  },
  {
    type: "value",
    title: "4.8 TrustPilot Rating: Excellent",
    description: `Rated 4.8 on TrustPilot, thousands trust us to deliver the best results. Experience the quality that keeps clients coming back!`,
    icon: "star-line",
  },
  {
    type: "value",
    title: "Reliable Service",
    description: `With a 98% order delivery rate and 10K+ returning customers, our reliability is unmatched. Enjoy our top-quality service!`,
    icon: "service-line",
  },
  {
    type: "value",
    title: "Instant Delivery",
    description: `No need to wait long to grow your account. Our platform auto-approves orders and delivers results within hours of ordering!`,
    icon: "flashlight-line",
  },
  {
    type: "value",
    title: "Great Support",
    description: `A seamless customer experience is what we strive for! Our team is ready 24/7 to assist with your orders and inquiries!`,
    icon: "customer-service-2-line",
  },
  {
    type: "value",
    title: "Affordable Prices",
    description: `Our pricing ensures high quality and fast delivery at great value. Don't waste time searching for a better deal elsewhere!`,
    icon: "price-tag-3-line",
  },
];

const TESTIMONIALS: TestimonialCard[] = [
  {
    type: "testimonial",
    name: "Sophie Johnson",
    title: "Highly Recommend",
    description: `Famepeak has truly elevated my TikTok presence! My videos reach more people, and their customer support is fantastic!`,
    avatarVariant: 1,
  },
  {
    type: "testimonial",
    name: "Martin Gomez",
    title: "Reliable Growth Partner",
    description: `Famepeak has helped us gain thousands of followers with ease. Their expertise in TikTok growth is unmatched!`,
    avatarVariant: 2,
  },
  {
    type: "testimonial",
    name: "Aaron Mccarty",
    title: "Fantastic Service",
    description: `I wasn't sure this would work, but the results were immediate! Famepeak exceeded my expectations – thank you!`,
    avatarVariant: 3,
  },
  {
    type: "testimonial",
    name: "Erica West",
    title: "Best Way to Gain Followers",
    description: `Famepeak is the easiest way to grow on TikTok. It's worked for me, and now my friends are using it too!`,
    avatarVariant: 4,
  },
  {
    type: "testimonial",
    name: "Lois Dean",
    title: "Top-Notch Experience",
    description: `From start to finish, Famepeak has been incredible. Quick responses and reliable service – highly recommend!`,
    avatarVariant: 5,
  },
  {
    type: "testimonial",
    name: "James Welch",
    title: "Impressed with Natural Growth",
    description: `I wanted genuine growth for my TikTok, and Famepeak delivered. So much better than any other service!`,
    avatarVariant: 6,
  },
];

// Combine and interleave cards
const CARDS: Card[] = VALUES.reduce<Card[]>((acc, value, index) => {
  acc.push(value);
  if (TESTIMONIALS[index]) {
    acc.push(TESTIMONIALS[index]);
  }
  return acc;
}, []);

export function PromiseCarousel({ className }: PromiseCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideOffset, setSlideOffset] = useState(280); // Default mobile offset
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);
  const [autoplayPaused, setAutoplayPaused] = useState(false);

  // Auto advance slides every 5 seconds
  useEffect(() => {
    if (autoplayPaused) return;

    const timer = setInterval(() => {
      setCurrentIndex((prev) => {
        if (prev === CARDS.length - 1) return 0;
        return prev + 1;
      });
    }, 5000);

    return () => clearInterval(timer);
  }, [autoplayPaused]);

  // Reset autoplay pause after 5 seconds when manually navigating
  useEffect(() => {
    if (autoplayPaused) {
      const timer = setTimeout(() => {
        setAutoplayPaused(false);
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [autoplayPaused]);

  // Minimum swipe mesafesi
  const minSwipeDistance = 50;

  const handleTouchStart = (e: React.TouchEvent) => {
    setAutoplayPaused(true);
    setTouchStart(e.targetTouches[0].clientX);
    setTouchEnd(null);
    setIsDragging(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchStart) return;

    const currentTouch = e.targetTouches[0].clientX;
    setTouchEnd(currentTouch);
    const diff = touchStart - currentTouch;
    setDragOffset(diff);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    setIsDragging(false);
    setDragOffset(0);

    const swipeDistance = touchStart - touchEnd;

    if (Math.abs(swipeDistance) > minSwipeDistance) {
      if (swipeDistance > 0) {
        if (currentIndex === CARDS.length - 1) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex(prev => prev + 1);
        }
      } else {
        if (currentIndex === 0) {
          setCurrentIndex(CARDS.length - 1);
        } else {
          setCurrentIndex(prev => prev - 1);
        }
      }
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const nextSlide = useCallback(() => {
    setCurrentIndex((prev) => (prev + 1) % CARDS.length);
  }, []);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prev) => (prev - 1 + CARDS.length) % CARDS.length);
  }, []);

  const handleManualNavigation = (direction: "prev" | "next") => {
    setAutoplayPaused(true);
    
    if (direction === "prev") {
      setCurrentIndex((prev) => {
        if (prev === 0) return CARDS.length - 1;
        return prev - 1;
      });
    } else {
      setCurrentIndex((prev) => {
        if (prev === CARDS.length - 1) return 0;
        return prev + 1;
      });
    }
  };

  // Ekran boyutunu dinleyen effect
  useEffect(() => {
    function handleResize() {
      // md breakpoint (768px) üzerinde 250px, altında 280px kullan
      setSlideOffset(window.innerWidth >= 768 ? 250 : 280);
    }

    // İlk render'da çalıştır
    handleResize();

    // Resize event listener ekle
    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={cn(
        "w-full max-w-full max-h-[230px] md:max-w-full mx-auto pt-3 pr-3 pb-0 pl-4 bg-white rounded-[12px] overflow-hidden",
        className
      )}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-end justify-between">
          <div>
            <h2 className="text-body-xs-m text-gray-900">
              Our Promise ✨ Real Results
            </h2>
            <p className="text-body-2xs-r text-gray-400">
              Discover our values and customer reviews.
            </p>
          </div>
          <div className="flex gap-2">
            <div
              className="w-7 h-7 border border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
              onClick={() => handleManualNavigation("prev")}
            >
              <Icon icon="arrow-left-line" className="w-5 h-5 text-gray-900" />
            </div>
            <div
              className="w-7 h-7 border border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
              onClick={() => handleManualNavigation("next")}
            >
              <Icon icon="arrow-right-line" className="w-5 h-5 text-gray-900" />
            </div>
          </div>
        </div>

        <div className="relative w-full overflow-hidden">
          <div
            className={cn(
              "flex transition-transform duration-300 ease-in-out will-change-transform",
              isDragging && "transition-none"
            )}
            style={{
              transform: `translateX(calc(-${currentIndex * slideOffset}px - ${
                isDragging ? dragOffset : 0
              }px))`,
              width: `${CARDS.length * slideOffset}px`,
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {CARDS.map((card, index) => (
              <div 
                key={index} 
                className="w-[260px] pb-4 mr-5 touch-pan-y flex-shrink-0"
                style={{
                  scrollSnapAlign: 'start',
                }}
              >
                {card.type === "value" ? (
                  <ValueCardComponent card={card} />
                ) : (
                  <TestimonialCardComponent card={card} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ValueCardComponent({ card }: { card: ValueCard }) {
  const iconStyle = ICON_STYLES[card.icon];

  return (
    <div className="bg-gray-50 max-h-[152px] p-4 pt-3 rounded-[8px] border border-gray-100 relative">
      <div className="flex flex-col items-center text-center">
        <div
          style={{
            ...iconStyle,
            border: "2.5px solid rgba(255, 255, 255, 0.56)",
          }}
          className="w-8 h-8 rounded-full flex items-center justify-center mb-3"
        >
          <Icon icon={card.icon} className="w-4 h-4 text-white" />
        </div>
        <Separator orientation="horizontal" className="mb-3" />
        <h3 className="text-body-2xs-m text-gray-900 mb-1">{card.title}</h3>
        <p className="text-body-2xs-r text-gray-400">{card.description}</p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="2"
        viewBox="0 0 42 2"
        fill="none"
        className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[1px]"
      >
        <path d="M1 1L41 0.999998" stroke="#375DFB" strokeLinecap="round" />
      </svg>
    </div>
  );
}

function TestimonialCardComponent({ card }: { card: TestimonialCard }) {
  return (
    <div className="p-4 pt-3 rounded-[8px] border bg-gray-50 h-[152px] max-h-[152px] border-gray-100 relative">
      <div className="flex flex-col items-center text-center">
        <div className="flex items-center">
          <div className="relative">
            <img
              src={`/avatars/avatar-${card.avatarVariant}.webp`}
              alt={card.name}
              className="w-8 h-8 rounded-full"
            />
            <VerifiedBadge className="top-0 right-0 h-3 w-3" />
          </div>
          <div className="flex flex-col gap-0 ml-2">
            <span className="text-body-2xs-m text-left text-gray-900">
              {card.name}
            </span>
            <div>
              <StarRating rating={5} maxStars={5} />
            </div>
          </div>
        </div>
        <Separator orientation="horizontal" className="mt-3" />
        <div
          className="relative"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='178' height='178' viewBox='0 0 178 178' fill='rgba(229, 231, 235, 0.5)'%3E%3Cpath d='M131.021 58.8546C136.857 65.0527 139.99 72.0042 139.99 83.2729C139.99 103.102 126.07 120.875 105.827 129.662L100.768 121.855C119.662 111.634 123.356 98.3715 124.829 90.0092C121.787 91.5842 117.804 92.1338 113.9 91.7712C103.68 90.8251 95.6234 82.4344 95.6234 72.0042C95.6234 66.7452 97.7125 61.7015 101.431 57.9828C105.15 54.2641 110.194 52.175 115.453 52.175C121.532 52.175 127.345 54.9511 131.021 58.8546V58.8546ZM74.3664 58.8546C80.2019 65.0527 83.3349 72.0042 83.3349 83.2729C83.3349 103.102 69.4147 120.875 49.1719 129.662L44.1126 121.855C63.007 111.634 66.701 98.3715 68.174 90.0092C65.1316 91.5842 61.1488 92.1338 57.2452 91.7712C47.0247 90.8251 38.9683 82.4344 38.9683 72.0042C38.9683 66.7452 41.0574 61.7015 44.7762 57.9828C48.4949 54.2641 53.5385 52.175 58.7976 52.175C64.8767 52.175 70.6895 54.9511 74.3664 58.8546V58.8546Z'/%3E%3C/svg%3E")`,
            backgroundPosition: "center -46px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "178px",
            paddingTop: "12px",
          }}
        >
          <div className="relative z-10">
            <h3 className="text-body-2xs-m text-gray-900 mb-1">{card.title}</h3>
            <p className="text-body-2xs-r text-gray-400">{card.description}</p>
          </div>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="2"
        viewBox="0 0 42 2"
        fill="none"
        className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[1px]"
      >
        <path d="M1 1L41 0.999998" stroke="#38C793" strokeLinecap="round" />
      </svg>
    </div>
  );
}
