import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import PaymentForm from "./payment/payment-form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { getCouponCode } from "@/lib/api";
import { Loader2 } from "lucide-react";
import { useNotify } from "@/hooks/useNotify";
import actions from "@/store/actions";
import { trackBeginCheckout } from "@/utils/analytics";
import { useSnapshot } from "valtio";
import state, { Followers, PostBasedProduct } from "@/store/state";
import { priceList } from "@/config";
import { Services } from "@/store/state";
import PaymentAssuranceDialog from "./payment/payment-assurance-dialog";

interface CouponData {
  code: string;
  amount: string;
}

const PaymentPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [couponData, setCouponData] = useState<CouponData | null>(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [hasCouponError, setHasCouponError] = useState(false);
  const { notify } = useNotify();
  const snap = useSnapshot(state);
  const [showAssuranceDialog, setShowAssuranceDialog] = useState(true);

  const showNotification = useCallback(
    (title: string, description: string, variant: "green" | "red") => {
      setTimeout(() => {
        notify(title, description, { variant });
      }, 100);
    },
    [notify]
  );

  useEffect(() => {
    actions.fetchIpInfo();

    // trackBeginCheckout fonksiyonunu çağır
    trackBeginCheckout(Number(actions.calculateTotalAmount()));
  }, [snap.cart]);

  const fetchCouponData = useCallback(
    async (couponCode: string) => {
      setIsLoading(true);
      try {
        const data = await getCouponCode(couponCode);
        if (data && data.length > 0) {
          setCouponData(data[0]);
          setTimeout(() => {
            setIsLoading(false);
            showNotification(
              "Coupon Applied",
              `Coupon ${data[0].code} has been applied successfully.`,
              "green"
            );
            setShowPaymentForm(true);
          }, 2000);
        } else {
          setTimeout(() => {
            setIsLoading(false);
            showNotification(
              "Invalid Coupon",
              "The provided coupon code is invalid or expired.",
              "red"
            );
            setHasCouponError(true);
            setShowPaymentForm(true);
          }, 2000);
        }
      } catch (error) {
        console.error("Error fetching coupon data:", error);
        setTimeout(() => {
          setIsLoading(false);
          showNotification(
            "Error",
            "An error occurred while applying the coupon.",
            "red"
          );
          setHasCouponError(true);
          setShowPaymentForm(true);
        }, 2000);
      }
    },
    [showNotification]
  );

  useEffect(() => {
    const couponCode = searchParams.get("coupon");
    if (couponCode) {
      fetchCouponData(couponCode);
    } else {
      setShowPaymentForm(true);
    }
  }, [searchParams, fetchCouponData]);

  const handleApplyCoupon = async (code: string) => {
    setShowPaymentForm(false);
    await fetchCouponData(code);
  };

  const handleRemoveCoupon = () => {
    setCouponData(null);
    showNotification(
      "Coupon Removed",
      "The coupon has been removed successfully.",
      "green"
    );
  };

  return (
    <div className="pt-5">
      <PaymentAssuranceDialog 
        open={showAssuranceDialog} 
        onOpenChange={setShowAssuranceDialog} 
      />
      
      <Dialog open={isLoading} onOpenChange={setIsLoading}>
        <DialogContent className="sm:max-w-[425px] px-4 max-w-[320px] rounded-md">
          <DialogHeader>
            <DialogTitle className="text-gray-900 text-body-md-m text-center">
              Applying Coupon
            </DialogTitle>
          </DialogHeader>
          <div className="flex items-center justify-center space-x-2">
            <Loader2 className="h-6 w-6 animate-spin" />
            <p className="text-center text-gray-500 text-body-xs-r">
              Please wait while we apply your coupon.
            </p>
          </div>
        </DialogContent>
      </Dialog>
      
      {showPaymentForm && (
        <PaymentForm
          couponData={couponData}
          onApplyCoupon={handleApplyCoupon}
          onRemoveCoupon={handleRemoveCoupon}
          hasCouponError={hasCouponError}
        />
      )}
    </div>
  );
};

export default PaymentPage;
