import state, {
  IpInfo,
  AccessTokenResponse,
  Step,
  Services,
  User,
  UserFeed,
  Cart,
  Products,
  Followers,
  PostBasedProduct,
  Steps,
  OrderStatus,
  ServiceSubStep,
} from "./state";
import api from "../lib/api";
import { priceList } from "@/config";
import { DrawerState } from "@/types/drawer-states";
import ky from "ky";
import { trackAddToCart } from "@/utils/analytics";
import { snapshot } from "valtio";

const ACCESS_TOKEN_EXPIRY = 24 * 60 * 60 * 1000; // 1 day in milliseconds
const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 2000; // 2 seconds
const FEED_MAX_RETRIES = 3;
const FEED_RETRY_DELAY = 500; // 500 milliseconds

export const actions = {
  async initializeData() {
    if (!state.ipInfo) {
      await this.fetchIpInfo();
    }
    await this.getAccessToken();
  },

  calculateTotalAmount: (): string => {
    let discountedTotal = 0;
    const store = snapshot(state);

    store.cart.forEach((userCart) => {
      Object.entries(userCart.products).forEach(([service, products]) => {
        products.forEach((product) => {
          const packInfo = priceList[service as Services].find(
            (pack) => pack.id.toString() === product.service_id
          );
          if (packInfo) {
            if (service === Services.FOLLOWERS) {
              const followerProduct = product as Followers;
              discountedTotal += packInfo.price * followerProduct.count;
            } else {
              const postBasedProduct = product as PostBasedProduct;
              const postCount = postBasedProduct.posts.length;
              discountedTotal += packInfo.price * postCount;
            }
          }
        });
      });
    });

    return discountedTotal.toFixed(2);
  },

  async fetchIpInfo() {
    try {
      const response = await fetch("https://ifconfig.famepeak.io/json");
      const data: IpInfo = await response.json();
      state.ipInfo = {
        ip: data.ip,
        ip_decimal: data.ip_decimal,
        country: data.country,
        country_iso: data.country_iso,
        country_eu: data.country_eu,
        region_name: data.region_name,
        region_code: data.region_code,
        zip_code: data.zip_code,
        city: data.city,
        latitude: data.latitude,
        longitude: data.longitude,
        time_zone: data.time_zone,
        asn: data.asn,
        asn_org: data.asn_org,
      };
    } catch (error) {
      console.error("Error fetching IP info:", error);
    }
  },

  async getAccessToken() {
    if (!state.ipInfo) {
      console.error("IP info not available. Cannot get access token.");
      return;
    }

    const now = Date.now();
    const shouldRefreshToken =
      !state.ipInfo ||
      !state.lastGeneratedData ||
      now - state.lastGeneratedData > ACCESS_TOKEN_EXPIRY;

    if (shouldRefreshToken) {
      try {
        const userAgent = navigator.userAgent;
        const data = await api
          .get(
            `getAccessToken?ip=${
              state.ipInfo.ip
            }&userAgent=${encodeURIComponent(userAgent)}`
          )
          .json<AccessTokenResponse>();
        state.accessToken = data.accessTokenKey;
        state.lastGeneratedData = data.lastGeneratedData;
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    }
  },

  async searchTikTokUser(username: string, retryCount = 0): Promise<void> {
    await this.initializeData();
    state.isLoading = true;
    state.error = null;
    try {
      const data = await api
        .get(`tiktokUserSearch/${username}?accessToken=${state.accessToken}`)
        .json<User>();

      if (data.timeout) {
        if (retryCount < MAX_RETRY_ATTEMPTS) {
          console.log(
            `Retry attempt ${retryCount + 1} of ${MAX_RETRY_ATTEMPTS}`
          );
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
          return this.searchTikTokUser(username, retryCount + 1);
        } else {
          throw new Error(
            "Maximum retry attempts reached. Please try again later."
          );
        }
      }

      if (data.accountPrivacy === 1) {
        throw new Error(
          "We do not accept orders for private accounts. Please make your TikTok account public before placing your order and ensure it stays public until we deliver your service completely."
        );
      }

      if (!data.username) {
        throw new Error(
          `${username} is not found, please type your TikTok username correctly.`
        );
      }

      state.user = {
        id: 0,
        name: data.name,
        email: "",
        username: data.username.toLowerCase(),
        profilePicture: data.profilePicture,
        totalFollower: data.totalFollower,
        totalLike: data.totalLike,
        service: data.service,
        accountPrivacy: data.accountPrivacy,
        secuid: data.secuid,
        error: data.error,
        timeout: data.timeout,
      };

      // User bilgileri hemen döndürülüyor
      state.isLoading = false;

      // Feed verisi arka planda yükleniyor
      if (state.user && state.accessToken) {
        this.fetchUserFeed(FEED_MAX_RETRIES);
      }
    } catch (error) {
      state.error = error instanceof Error ? error.message : String(error);
      state.user = null;
      state.isLoading = false;
    }
  },

  async fetchUserFeed(maxRetries: number): Promise<void> {
    let retryCount = 0;
    while (retryCount < maxRetries) {
      try {
        const feedData = await api
          .get(
            `tiktokUserFeed/${state.user!.username}?secuid=${
              state.user!.secuid
            }&accessToken=${state.accessToken}`
          )
          .json<UserFeed>();

        if (feedData.error && feedData.errorCode === 2) {
          console.log("Feed error occurred, retrying...");
          retryCount++;
          await new Promise((resolve) => setTimeout(resolve, FEED_RETRY_DELAY));
          continue;
        }

        if (feedData.error && feedData.errorCode === 1) {
          state.feedError =
            "You cannot buy this service for this account because it has no video.";
          return;
        }

        if (feedData && feedData.items && feedData.items.length > 0) {
          state.userFeed = feedData;
          return;
        } else {
          state.feedError = "No videos found for this account.";
          return;
        }
      } catch (error) {
        console.error(
          `Error fetching user feed (attempt ${retryCount + 1}):`,
          error
        );
        retryCount++;
        if (retryCount >= maxRetries) {
          state.feedError =
            "We couldn't get your TikTok videos. If you're sure the account has a video, please refresh the page and try again.";
        }
      }
    }
  },

  async fetchOrderStatus(orderId: string): Promise<OrderStatus> {
    try {
      state.isLoading = true;
      const data = await ky
        .get(`https://api.famepeak.io/v2/checkOrderDetail/${orderId}`, {
          timeout: 30000,
        })
        .json<OrderStatus>();
      state.isLoading = false;
      return data;
    } catch (error) {
      console.error("Error fetching order status:", error);
      state.error = null;
      state.isLoading = false;
      throw error; // Re-throw the error or return a default OrderStatus
    }
  },

  updateCurrentStep(step: Step) {
    state.currentStep = step;
    if (step === Steps.CHECKOUT) {
      state.showBasketSummary = false;
    } else if (step === Steps.CHOOSE_SERVICE && state.cart.length > 0) {
      state.showBasketSummary = true;
    }
  },

  setCurrentSelectedService(service: Services) {
    state.currentSelectedService = service;
  },

  addSelectedService(
    user: User,
    service: Services,
    packId: number,
    selectedPostIds: string[] = []
  ) {
    const selectedPack = priceList[service].find((pack) => pack.id === packId);
    if (!selectedPack) return;

    const quantity = selectedPack.amount;

    // Update lastAddedService first
    state.lastAddedService = {
      service,
      amount: quantity,
    };

    const userCartIndex = state.cart.findIndex(
      (item) => item.username === user.username
    );

    if (userCartIndex === -1) {
      // Yeni kullanıcı sepeti oluştur
      const newUserCart: Products = {
        followers: [],
        likes: [],
        shares: [],
        views: [],
      };
      if (service === Services.FOLLOWERS) {
        newUserCart[service] = [
          {
            service_id: packId.toString(),
            amount: quantity,
            count: 1,
          },
        ];
      } else {
        newUserCart[service] = [
          {
            service_id: packId.toString(),
            amount: quantity,
            posts: selectedPostIds,
          },
        ];
      }
      state.cart.push({
        username: user.username,
        products: newUserCart,
      });

      // Analytics: Add to Cart
      trackAddToCart(selectedPack.price, packId.toString());
    } else {
      const userCart = state.cart[userCartIndex];
      if (!userCart.products[service]) {
        userCart.products[service] = [];
      }

      if (service === Services.FOLLOWERS) {
        const serviceIndex = userCart.products[service]!.findIndex(
          (s) => s.service_id === packId.toString()
        );
        if (serviceIndex === -1) {
          (userCart.products[service] as Followers[]).push({
            service_id: packId.toString(),
            amount: quantity,
            count: 1,
          });
          // Analytics: Add to Cart
          trackAddToCart(selectedPack.price, packId.toString());
        } else {
          (userCart.products[service] as Followers[])[serviceIndex].count += 1;
          // Analytics: Add to Cart (increment)
          trackAddToCart(selectedPack.price, packId.toString());
        }
      } else {
        // For LIKES, VIEWS, SHARES
        const existingProduct = (
          userCart.products[service] as PostBasedProduct[]
        ).find((p) => p.service_id === packId.toString());
        if (existingProduct) {
          // Merge new posts with existing ones, removing duplicates
          const newPosts = selectedPostIds.filter(
            (postId) => !existingProduct.posts.includes(postId)
          );
          existingProduct.posts = [...existingProduct.posts, ...newPosts];
          // Analytics: Add to Cart (for new posts only)
          if (newPosts.length > 0) {
            trackAddToCart(
              selectedPack.price * newPosts.length,
              packId.toString()
            );
          }
        } else {
          (userCart.products[service] as PostBasedProduct[]).push({
            service_id: packId.toString(),
            amount: quantity,
            posts: selectedPostIds,
          });
          // Analytics: Add to Cart
          trackAddToCart(
            selectedPack.price * selectedPostIds.length,
            packId.toString()
          );
        }
      }
    }

    // Sepete bir şey eklendiğinde showBasketSummary'yi true yap
    state.showBasketSummary = true;
  },

  resetUser() {
    state.user = null;
    state.cart = [];
    state.currentSelectedService = null;
    state.lastAddedService = {
      service: null,
      amount: 0,
    };
  },

  togglePostSelection(postId: string) {
    const index = state.selectedPostIds.indexOf(postId);
    if (index > -1) {
      state.selectedPostIds.splice(index, 1);
    } else {
      state.selectedPostIds.push(postId);
    }
    // UserFeed'deki ilgili post'un isSelected özelliğini güncelle
    if (state.userFeed) {
      const post = state.userFeed.items.find((item) => item.id === postId);
      if (post) {
        post.isSelected = !post.isSelected;
      }
    }
  },

  selectAllPosts() {
    if (state.userFeed) {
      state.selectedPostIds = state.userFeed.items.map((item) => item.id);
      state.userFeed.items.forEach((item) => (item.isSelected = true));
    }
  },

  deselectAllPosts() {
    state.selectedPostIds = [];
    if (state.userFeed) {
      state.userFeed.items.forEach((item) => (item.isSelected = false));
    }
  },

  setDrawerState(newState: DrawerState) {
    state.drawerState = newState;
  },

  resetServiceSpecificState() {
    state.selectedPostIds = [];
    if (state.userFeed) {
      state.userFeed.items.forEach((item) => (item.isSelected = false));
    }
    state.drawerState = DrawerState.CLOSED;
  },

  setSelectedPackId(packId: number | null) {
    state.selectedPackId = packId;
  },

  handleAddToBasket() {
    if (
      state.selectedPackId !== null &&
      state.user &&
      state.currentSelectedService
    ) {
      this.addSelectedService(
        state.user,
        state.currentSelectedService,
        state.selectedPackId,
        state.selectedPostIds
      );

      console.log("Service added to basket", state.currentSelectedService);

      this.resetServiceSpecificState();
    }
  },

  hideBasketSummary() {
    state.showBasketSummary = false;
  },

  resetAllState() {
    state.user = null;
    state.cart = [];
    state.currentSelectedService = null;
    state.lastAddedService = {
      service: null,
      amount: 0,
    };
    state.ipInfo = null;
    state.accessToken = null;
    state.lastGeneratedData = null;
    state.isLoading = false;
    state.error = null;
    state.currentStep = Steps.SELECT_ACCOUNT;
    state.userFeed = null;
    state.selectedPostIds = [];
    state.drawerState = DrawerState.CLOSED;
    state.selectedPackId = null;
    state.showBasketSummary = false;
    state.currentCarouselIndex = 0;
    state.isCarouselAutoPlaying = true;
  },

  checkUserFeed(): { isValid: boolean; error: string | null } {
    if (!state.userFeed) {
      return { isValid: false, error: "User feed is not available." };
    }

    if (state.userFeed.error && state.userFeed.errorCode === 1) {
      return {
        isValid: false,
        error:
          "You cannot buy this service for this account because it has no video.",
      };
    }

    return { isValid: true, error: null };
  },

  removeFromCart: (username: string, service: Services, serviceId: string) => {
    state.cart = state.cart.map((userCart) => {
      if (userCart.username === username) {
        const updatedProducts = { ...userCart.products };
        updatedProducts[service] = updatedProducts[service].filter(
          (item) => item.service_id !== serviceId
        );
        return { ...userCart, products: updatedProducts };
      }
      return userCart;
    });

    // Remove the user from the cart if they have no products left
    state.cart = state.cart.filter((userCart) =>
      Object.values(userCart.products).some((products) => products.length > 0)
    );
  },

  decreaseQuantity: (
    username: string,
    service: Services,
    serviceId: string
  ) => {
    state.cart = state.cart.map((userCart) => {
      if (userCart.username === username) {
        const updatedProducts = { ...userCart.products };
        updatedProducts[service] = updatedProducts[service].map((item) => {
          if (item.service_id === serviceId) {
            if (service === Services.FOLLOWERS) {
              const followerItem = item as Followers;
              if (followerItem.count > 1) {
                return { ...followerItem, count: followerItem.count - 1 };
              }
            } else {
              const postBasedItem = item as PostBasedProduct;
              if (postBasedItem.posts.length > 1) {
                return {
                  ...postBasedItem,
                  posts: postBasedItem.posts.slice(0, -1),
                };
              }
            }
          }
          return item;
        });
        return { ...userCart, products: updatedProducts };
      }
      return userCart;
    });
  },

  increaseQuantity: (
    username: string,
    service: Services,
    serviceId: string
  ) => {
    state.cart = state.cart.map((userCart) => {
      if (userCart.username === username) {
        const updatedProducts = { ...userCart.products };
        updatedProducts[service] = updatedProducts[service].map((item) => {
          if (item.service_id === serviceId) {
            if (service === Services.FOLLOWERS) {
              const followerItem = item as Followers;
              const newCount = followerItem.count + 1;

              // Yeni eklenen: trackAddToCart çağrısı
              const selectedPack = priceList[service].find(
                (pack) => pack.id.toString() === serviceId
              );
              if (selectedPack) {
                trackAddToCart(selectedPack.price, serviceId);
              }

              return { ...followerItem, count: newCount };
            } else {
              // For post-based services, we can't increase quantity
              // as it depends on the number of selected posts
              return item;
            }
          }
          return item;
        });
        return { ...userCart, products: updatedProducts };
      }
      return userCart;
    });
  },

  openServiceSpecificDrawer: (
    service: Services,
    username: string,
    serviceId: string
  ) => {
    state.currentSelectedService = service;
    state.drawerState = DrawerState.SERVICE_SPECIFIC;

    // Find the specific product in the cart
    const userCart = state.cart.find((cart) => cart.username === username);
    if (userCart) {
      const product = userCart.products[service].find(
        (p) => p.service_id === serviceId
      );
      if (product && "posts" in product) {
        state.selectedPostIds = [...product.posts];

        // Update the isSelected property of the posts in userFeed
        if (state.userFeed) {
          state.userFeed.items = state.userFeed.items.map((item) => ({
            ...item,
            isSelected: state.selectedPostIds.includes(item.id),
          }));
        }
      }
    }

    // Set the selected pack ID
    state.selectedPackId = Number(serviceId);
  },

  updateCartItem: (
    username: string,
    service: Services,
    oldServiceId: string,
    newServiceId: string,
    selectedPostIds: readonly string[]
  ) => {
    console.log(username, service, oldServiceId, newServiceId, selectedPostIds);
    const userCartIndex = state.cart.findIndex(
      (item) => item.username === username
    );

    if (userCartIndex !== -1) {
      const userCart = { ...state.cart[userCartIndex] };
      let serviceProducts = [...userCart.products[service]];

      // Find the product with the old service ID
      const oldProductIndex = serviceProducts.findIndex(
        (p) => p.service_id === oldServiceId
      );

      if (oldProductIndex !== -1) {
        // Remove the old product
        const oldProduct = serviceProducts[oldProductIndex];
        serviceProducts = serviceProducts.filter(
          (_, index) => index !== oldProductIndex
        );

        // Check if there's an existing product with the new service ID
        const existingProductIndex = serviceProducts.findIndex(
          (p) => p.service_id === newServiceId
        );

        if (existingProductIndex !== -1) {
          // Merge with existing product
          serviceProducts = serviceProducts.map((product, index) => {
            if (index === existingProductIndex) {
              if (service === Services.FOLLOWERS) {
                const newCount =
                  (product as Followers).count +
                  (oldProduct as Followers).count;

                // Yeni eklenen: trackAddToCart çağrısı
                const selectedPack = priceList[service].find(
                  (pack) => pack.id.toString() === newServiceId
                );
                if (selectedPack) {
                  trackAddToCart(
                    selectedPack.price *
                      (newCount - (product as Followers).count),
                    newServiceId
                  );
                }

                return {
                  ...product,
                  count: newCount,
                };
              } else {
                const newPosts = selectedPostIds.filter(
                  (postId) =>
                    !(product as PostBasedProduct).posts.includes(postId)
                );

                // Yeni eklenen: trackAddToCart çağrısı
                const selectedPack = priceList[service].find(
                  (pack) => pack.id.toString() === newServiceId
                );
                if (selectedPack) {
                  trackAddToCart(
                    selectedPack.price * newPosts.length,
                    newServiceId
                  );
                }

                return {
                  ...product,
                  posts: Array.from(
                    new Set([
                      ...(product as PostBasedProduct).posts,
                      ...selectedPostIds,
                    ])
                  ),
                };
              }
            }
            return product;
          });
        } else {
          // Create a new product
          const newProduct =
            service === Services.FOLLOWERS
              ? {
                  service_id: newServiceId,
                  amount: (oldProduct as Followers).amount,
                  count: (oldProduct as Followers).count,
                }
              : {
                  service_id: newServiceId,
                  amount: (oldProduct as PostBasedProduct).amount,
                  posts: [...selectedPostIds],
                };

          // Yeni eklenen: trackAddToCart çağrısı
          const selectedPack = priceList[service].find(
            (pack) => pack.id.toString() === newServiceId
          );
          if (selectedPack) {
            if (service === Services.FOLLOWERS) {
              trackAddToCart(
                selectedPack.price * (newProduct as Followers).count,
                newServiceId
              );
            } else {
              trackAddToCart(
                selectedPack.price * selectedPostIds.length,
                newServiceId
              );
            }
          }

          serviceProducts = [...serviceProducts, newProduct];
        }

        // Update the cart
        const updatedProducts = {
          ...userCart.products,
          [service]: serviceProducts,
        };
        const updatedUserCart = { ...userCart, products: updatedProducts };
        state.cart = state.cart.map((cart, index) =>
          index === userCartIndex ? updatedUserCart : cart
        );
      }
    }

    // Update userFeed selection
    if (state.userFeed) {
      state.userFeed = {
        ...state.userFeed,
        items: state.userFeed.items.map((item) => ({
          ...item,
          isSelected: selectedPostIds.includes(item.id),
        })),
      };
    }

    // Update selectedPostIds
    state.selectedPostIds = [...selectedPostIds];
  },

  updateUserFeedSelection: (selectedPostIds: string[]) => {
    if (state.userFeed) {
      state.userFeed.items = state.userFeed.items.map((item) => ({
        ...item,
        isSelected: selectedPostIds.includes(item.id),
      }));
    }
  },

  updateBasketInCheckout: (
    service: Services,
    serviceId: string,
    postsToRemove: string[],
    postsToAdd: string[]
  ) => {
    if (!state.user) return;

    const userCartIndex = state.cart.findIndex(
      (item) => item.username === state.user!.username
    );
    if (userCartIndex === -1) return;

    const userCart = state.cart[userCartIndex];
    const serviceProducts = userCart.products[service];

    if (!serviceProducts || serviceProducts.length === 0) return;

    // Find the specific product by service_id
    const productIndex = serviceProducts.findIndex(
      (p) => p.service_id === serviceId
    );
    if (productIndex === -1) return;

    const product = { ...serviceProducts[productIndex] } as PostBasedProduct;

    // Remove posts
    product.posts = product.posts.filter(
      (postId) => !postsToRemove.includes(postId)
    );

    // Add new posts
    product.posts = [...new Set([...product.posts, ...postsToAdd])];

    // Update the specific product in the cart
    const updatedServiceProducts = [...serviceProducts];
    updatedServiceProducts[productIndex] = product;
    state.cart[userCartIndex].products[service] = updatedServiceProducts;

    // If the product has no posts left, remove it from the cart
    if (product.posts.length === 0) {
      state.cart[userCartIndex].products[service] = serviceProducts.filter(
        (_, index) => index !== productIndex
      );

      // If the service has no products left, remove it from the cart
      if (state.cart[userCartIndex].products[service].length === 0) {
        delete state.cart[userCartIndex].products[service];
      }

      // If the user's cart is empty, remove it from the main cart
      if (Object.keys(state.cart[userCartIndex].products).length === 0) {
        state.cart = state.cart.filter((_, index) => index !== userCartIndex);
      }
    }

    // Update userFeed selection
    if (state.userFeed) {
      state.userFeed.items = state.userFeed.items.map((item) => ({
        ...item,
        isSelected: product.posts.includes(item.id),
      }));
    }

    // Update selectedPostIds
    state.selectedPostIds = [...product.posts];
  },

  setServiceSubStep(step: ServiceSubStep) {
    state.serviceSubStep = step;
  },

  setError: (error: string | null) => {
    state.error = error;
  },

  resetCart() {
    state.cart = [];
    state.showBasketSummary = false;
    // Diğer state'leri sıfırlamayın
  },

  async processPayment(paymentData: {
    email: string;
    cardNumber: string;
    expirationDate: string;
    cvv: string;
    fullName: string;
    country: string;
    zipCode: string;
    amount: number;
    coupon?: string;
    queryParams?: Record<string, string>;
  }) {
    try {
      state.isLoading = true;
      const response = await ky
        .post("https://api.famepeak.io/checkout/process-payment", {
          json: {
            ...paymentData,
            orderDataArray: state.cart,
            queryParams: paymentData.queryParams,
          },
        })
        .json();

      state.isLoading = false;
      return response;
    } catch (error) {
      console.error("Error processing payment:", error);
      state.error = error instanceof Error ? error.message : String(error);
      state.isLoading = false;
      throw error;
    }
  },

  async confirmPayment(
    paymentIntent: string
  ): Promise<{ success: boolean; message: string }> {
    try {
      const response = await ky
        .get(
          `https://api.famepeak.io/checkout/confirm-payment/${paymentIntent}`
        )
        .json<{ success: boolean; message: string }>();
      return response;
    } catch (error) {
      console.error("Error confirming payment:", error);
      return {
        success: false,
        message: "An error occurred while confirming the payment.",
      };
    }
  },
};

export default actions;
