import React from "react";
import Icon from "./icon";

export const FollowersIcon = () => (
  <div
    style={{
      boxShadow:
        "0px 0px 0px 2.5px var(--Gray-100, #F4F4F5), 0px 2.5px 5.833px 2.5px var(--Gray-800, #27272A) inset",
    }}
    className="w-10 h-10 rounded-full bg-gray-900 flex items-center justify-center"
  >
    <Icon icon="emotion-fill" className="text-white h-6 w-6" />
  </div>
);

export const LikesIcon = () => (
  <div
    style={{
      boxShadow:
        "0px 0px 0px 2.5px var(--Red-100, #FDEDF0), 0px 2.5px 5.833px 2.5px var(--Red-300, #DF1C41) inset",
    }}
    className="w-10 h-10 rounded-full bg-red-300 flex items-center justify-center"
  >
    <Icon icon="heart-3-fill" className="text-white w-6 h-6" />
  </div>
);

export const ViewsIcon = () => (
  <div
    style={{
      boxShadow:
        "0px 2.5px 11.83px 2.5px var(--Green-300, #38C793) inset, 0px 0px 0px 2.5px var(--Green-100, #EFFAF6)",
    }}
    className="w-10 h-10 rounded-full bg-green-300 flex items-center justify-center"
  >
    <Icon icon="eye-fill" className="text-white w-6 h-6" />
  </div>
);

export const SharesIcon = () => (
  <div
    style={{
      boxShadow:
        "0px 0px 0px 2.5px var(--Orange-100, #FEF3EB), 0px 2.5px 5.833px 2.5px var(--Orange-300, #F17B2C) inset",
    }}
    className="w-10 h-10 rounded-full bg-orange-300 flex items-center justify-center"
  >
    <Icon icon="share-fill" className="text-white h-6 w-6" />
  </div>
);