import React from "react";
import { cn } from "@/lib/utils";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import Icon from "./icon";
import { VerifiedBadge } from "./profile-card";
import actions from "@/store/actions";
import { Separator } from "./ui/separator";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useSnapshot } from "valtio";
import state from "@/store/state";

interface ChooseServiceProfileCardProps {
  followers: number;
  likes: number;
  avatarUrl: string;
  isVerified?: boolean;
  className?: string;
}

export function ChooseServiceProfileCard({
  followers,
  likes,
  avatarUrl,
  isVerified = true,
  className,
}: ChooseServiceProfileCardProps) {
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const { cart } = useSnapshot(state);

  const handleAvatarClick = () => {
    // Temporary disable basket check
    // const hasProducts = cart.some((userCart) =>
    //   Object.values(userCart.products).some((products) => products.length > 0)
    // );

    // if (hasProducts) {
    //   setIsAlertOpen(true);
    //   return;
    // }

    // Always show the alert
    setIsAlertOpen(true);
  };

  const handleConfirm = () => {
    actions.resetAllState();
    setIsAlertOpen(false);
  };

  return (
    <div
      className={cn(
        "flex items-center justify-between p-3 rounded-2xl bg-white shadow-sm max-h-[64px]",
        className
      )}
    >
      <StatItem label="Followers" value={followers} />
      <div className="flex items-center h-full">
        <Separator orientation="vertical" className="h-8 mx-6" />
        <div onClick={handleAvatarClick} className="relative">
          <Avatar className="h-10 w-10 cursor-pointer">
            <AvatarImage src={avatarUrl} alt="Profile" />
            <AvatarFallback>PF</AvatarFallback>
          </Avatar>
          {isVerified && <VerifiedBadge className="absolute -top-1 -right-1" />}
          <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 bg-[#F4F4F5] w-[18px] h-[18px] rounded-[29px] border border-white flex items-center justify-center shadow-[-3px_4px_4px_-2px_rgba(19,19,19,0.20)]">
            <Icon
              icon="pencil-fill"
              className="bg-gray-100 h-4 cursor-pointer w-4 text-gray-900 rounded-full"
            />
          </div>
        </div>
        <Separator orientation="vertical" className="h-8 mx-6" />
      </div>
      <StatItem label="Likes" value={likes} />

      <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
        <AlertDialogContent className="max-w-[327px] rounded-[12px] px-4 py-5">
          <AlertDialogHeader className="space-y-1">
            <AlertDialogTitle className="!text-body-sm-m text-gray-900 text-center">
              Switch Account? 🤔
            </AlertDialogTitle>
            <AlertDialogDescription className="!text-body-2xs-r text-gray-400 text-center">
              You have services in your basket. Switching accounts will empty
              your basket. Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex flex-row justify-center items-center gap-4">
            <AlertDialogAction
              onClick={handleConfirm}
              className="bg-red-100 max-h-8 focus:bg-red-200 hover:border-red-300 hover:shadow-none focus:shadow-none !focus:ring-0 hover:ring-0 border flex-1 !border-white text-red-300 !text-body-xs-m shadow-[0px_1px_2px_0px_var(--Red-200,#F8C9D2),0px_0px_0px_1px_var(--Red-200,#F8C9D2)]"
            >
              Continue
            </AlertDialogAction>
            <AlertDialogCancel className="mt-0 flex-1 max-h-8 bg-gray-50 border-gray-100 text-body-xs-m">
              Cancel
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

interface StatItemProps {
  label: string;
  value: number;
}

function StatItem({ label, value }: StatItemProps) {
  const formattedValue = React.useMemo(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    });
    const parts = formatter.formatToParts(value);
    return parts
      .map((part) => {
        if (part.type === "compact") {
          return ` ${part.value}`; // Add space before the unit
        }
        return part.value;
      })
      .join("");
  }, [value]);

  return (
    <div className="text-center flex-auto">
      <div className="text-body-xs-m">{formattedValue}</div>
      <div className="text-detail text-gray-400">{label}</div>
    </div>
  );
}
