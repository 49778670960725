import React, { useState, useEffect } from "react";
import { ProveSourceCard } from "./prove-source-card";
import { recentPurchases } from "@/data/recent-purchases";
import { AnimatePresence } from "framer-motion";
import { useSnapshot } from "valtio";
import state from "@/store/state";

export function ProveSourceNotification() {
  const [currentPurchase, setCurrentPurchase] = useState<number>(0);
  const [isVisible, setIsVisible] = useState(false);
  const snap = useSnapshot(state);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const showNext = () => {
      setIsVisible(true);

      setTimeout(() => {
        setIsVisible(false);

        setCurrentPurchase((prev) => (prev + 1) % recentPurchases.length);

        timeoutId = setTimeout(showNext, Math.random() * 10000 + 10000);
      }, 6000);
    };

    timeoutId = setTimeout(showNext, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (!snap.ipInfo?.country) return null;

  const getRandomTimeAgo = () => {
    const minutes = Math.floor(Math.random() * 56) + 5;
    return `${minutes}m ago`;
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <ProveSourceCard
          name={recentPurchases[currentPurchase].name}
          country={snap.ipInfo.country}
          timeAgo={getRandomTimeAgo()}
        />
      )}
    </AnimatePresence>
  );
}
