import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import Icon from "./icon";
import actions from "@/store/actions";
import { useNotify } from "@/hooks/useNotify";
import state, {
  Followers,
  PostBasedProduct,
  Services,
  Steps,
} from "@/store/state";
import { trackPurchase } from "@/utils/analytics";
import { priceList } from "@/config";
import { useSnapshot } from "valtio";

interface ThreeDCallbackResponse {
  status: "success" | "failure";
  message: string;
  transactionId: string;
}

export function ThreeDCallbackPage() {
  const [searchParams] = useSearchParams();
  const [callbackData, setCallbackData] =
    useState<ThreeDCallbackResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { notify } = useNotify();
  const { cart, currentSelectedService } = useSnapshot(state);

  const totalAmount = useMemo(() => {
    let discountedTotal = 0;

    cart.forEach((userCart) => {
      Object.entries(userCart.products).forEach(([service, products]) => {
        products.forEach((product) => {
          const packInfo = priceList[service as Services].find(
            (pack) => pack.id.toString() === product.service_id
          );
          if (packInfo) {
            if (service === Services.FOLLOWERS) {
              const followerProduct = product as Followers;
              discountedTotal += packInfo.price * followerProduct.count;
            } else {
              const postBasedProduct = product as PostBasedProduct;
              const postCount = postBasedProduct.posts.length;
              discountedTotal += packInfo.price * postCount;
            }
          }
        });
      });
    });

    return discountedTotal.toFixed(2);
  }, [cart]);

  useEffect(() => {
    const confirmPayment = async () => {
      setIsLoading(true);
      const paymentIntent = searchParams.get("payment_intent");

      if (paymentIntent) {
        try {
          const result = await actions.confirmPayment(paymentIntent);
          if (result.success) {
            setCallbackData({
              status: "success",
              message: result.message,
              transactionId: paymentIntent,
            });
            notify("Success", "Payment processed successfully!", {
              variant: "green",
            });

            trackPurchase(
              paymentIntent,
              state.user?.email || "",
              parseFloat(totalAmount)
            );

            setTimeout(() => {
              navigate(`/order/orderStatus?orderID=${paymentIntent}`);
              actions.resetCart(); // Sadece cart'ı resetle
              actions.updateCurrentStep(Steps.CHOOSE_SERVICE); // currentStep'i güncelle
            }, 2000);
          } else {
            setCallbackData({
              status: "failure",
              message: result.message,
              transactionId: paymentIntent,
            });
            notify("Error", result.message, { variant: "red" });
          }
        } catch (error) {
          console.error("Error confirming payment:", error);
          setCallbackData({
            status: "failure",
            message: "An error occurred while confirming the payment.",
            transactionId: paymentIntent,
          });
          notify("Error", "An error occurred while confirming the payment.", {
            variant: "red",
          });
        }
      } else {
        setCallbackData({
          status: "failure",
          message: "No payment intent found in the URL.",
          transactionId: "",
        });
        notify("Error", "No payment intent found in the URL.", {
          variant: "red",
        });
      }
      setIsLoading(false);
    };

    confirmPayment();
  }, [searchParams, navigate, notify, totalAmount]);

  return (
    <div className="min-h-screen mt-[48px] max-w-md mx-auto space-y-4 p-4 bg-gray-50">
      {isLoading ? (
        <div className="space-y-4">
          <Skeleton className="h-52 w-full" />
          <Skeleton className="h-32 w-full" />
        </div>
      ) : callbackData ? (
        <Card>
          <CardHeader>
            <h2 className="text-2xl font-bold text-center">
              {callbackData.status === "success"
                ? "Payment Successful"
                : "Payment Failed"}
            </h2>
          </CardHeader>
          <CardContent>
            <p className="text-center mb-4">{callbackData.message}</p>
            <Separator />
            <div className="mt-4 flex justify-between">
              <span>Transaction ID:</span>
              <span>{callbackData.transactionId}</span>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <p className="text-center text-red-500">
              No payment data available.
            </p>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default ThreeDCallbackPage;
