import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react"; // Yükleme ikonunu içe aktarıyoruz

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-[10px] !text-body-xs-m transition-colors focus-visible:outline-none disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default: cn(`
          rounded-[10px]
          border border-white
          text-white
          disabled:border-[#E4E4E7]
          disabled:bg-[#F4F4F5]
          disabled:text-gray-400
          disabled:shadow-none
        `),
        destructive: "bg-red-500 text-white hover:bg-red-400",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 px-3",
        lg: "h-11 px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean; // Yeni loading prop'u ekliyoruz
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      disabled,
      loading = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const isDefault = variant === "default" || !variant;

    const defaultStyle = {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Purple-300, #6E3FF3)",
    };

    const hoverStyle = {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Purple-300, #6E3FF3)",
    };

    const focusStyle = {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.16) 100%), var(--Purple-300, #6E3FF3)",
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isDefault && !disabled && !loading) {
        Object.assign(e.currentTarget.style, hoverStyle);
      }
    };

    const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isDefault && !disabled && !loading) {
        e.currentTarget.style.background = defaultStyle.background;
      }
    };

    const handleFocus = (e: React.FocusEvent<HTMLButtonElement>) => {
      if (isDefault && !disabled && !loading) {
        Object.assign(e.currentTarget.style, focusStyle);
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
      if (isDefault && !disabled && !loading) {
        e.currentTarget.style.background = defaultStyle.background;
      }
    };

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={disabled || loading}
        style={isDefault && !disabled && !loading ? defaultStyle : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      >
        {loading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          </>
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
