import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import Icon from "../icon";
import { FollowersIcon, LikesIcon, ViewsIcon, SharesIcon } from "../stat-icons";
import {
  Services,
  OrderStatus,
  Followers,
  PostBasedProduct,
} from "@/store/state";

const serviceIcons = {
  [Services.FOLLOWERS]: <FollowersIcon />,
  [Services.LIKES]: <LikesIcon />,
  [Services.VIEWS]: <ViewsIcon />,
  [Services.SHARES]: <SharesIcon />,
};

interface OrderDetailsProps {
  orderStatus: OrderStatus;
}

export default function OrderDetails({ orderStatus }: OrderDetailsProps) {
  const renderProductItems = (products: OrderStatus["products"]) => {
    return Object.entries(products)
      .flatMap(([service, items]) =>
        items.map((item) => {
          const isFollowerService = service === Services.FOLLOWERS;
          const quantity = isFollowerService
            ? (item as Followers).count
            : (item as PostBasedProduct).posts.length;

          return (
            <div
              key={`${service}-${item.service_id}`}
              className="flex items-center justify-between"
            >
              <div className="flex items-center space-x-2">
                {serviceIcons[service as Services]}
                <div>
                  <p className="text-gray-900 text-body-2xs-m">
                    +{item.amount.toLocaleString()} {service}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className="h-5 w-5 flex items-center justify-center bg-orange-100 text-detail text-orange-300 rounded-full border border-white"
                  style={{
                    boxShadow: "-3px 4px 4px -2px rgba(19, 19, 19, 0.20)",
                  }}
                >
                  {quantity}
                </div>
              </div>
            </div>
          );
        })
      )
      .filter(Boolean);
  };

  return (
    <Card className="w-full rounded-[8px] border border-gray-100 shadow-none max-w-full p-3 space-y-3 mb-4">
      <div className="flex items-center justify-between">
        <h3 className="text-gray-900 text-body-2xs-m">
          @{orderStatus.username}
        </h3>
      </div>
      <Separator className="!mt-4" />
      {renderProductItems(orderStatus.products)}
    </Card>
  );
}
