import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import Icon from "../icon";
import { Separator } from "../ui/separator";
import PaymentCardIcons from "./payment-card-icons";

interface PaymentAssuranceDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

interface CardIconWrapperProps {
  children: React.ReactNode;
}

const CardIconWrapper: React.FC<CardIconWrapperProps> = ({ children }) => {
  return (
    <div className="w-[32px] h-[16px] p-[4px_5px] flex justify-center items-center bg-white border border-gray-100 rounded-[12px]">
      {children}
    </div>
  );
};

const PaymentAssuranceDialog: React.FC<PaymentAssuranceDialogProps> = ({
  open,
  onOpenChange,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[327px] p-4 rounded-[12px]">
        <DialogHeader className="space-y-3">
          <DialogTitle className="text-body-xs-m text-center">
            Order with Confidence 🔐
          </DialogTitle>
          <DialogDescription className="!text-gray-400 text-center text-body-2xs-r !mt-1">
            Your order is in safe hands – complete it securely!
          </DialogDescription>
        </DialogHeader>

        <div className="px-4 space-y-3 py-3 bg-gray-50 border border-gray-100 rounded-[12px]">
          {/* 10-Day Guarantee Section */}
          <div className="space-y-3">
            <div
              style={{
                boxShadow:
                  "0px 0px 0px 3.125px var(--Orange-100, #FEF3EB), 0px 2.5px 5.833px 2.5px var(--Orange-300, #F17B2C) inset",
                border: "3.125px solid rgba(255, 255, 255, 0.56)",
              }}
              className="mx-auto w-10 h-10 rounded-full bg-orange-300 flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="!text-white !fill-white w-5 h-5"
              >
                <path
                  d="M5.72312 4.99215L12 3.59729L18.2769 4.99215C18.4465 5.02986 18.5982 5.12428 18.707 5.25982C18.8157 5.39537 18.875 5.56393 18.875 5.73771V13.3667C18.8749 14.1212 18.6886 14.864 18.3326 15.5292C17.9765 16.1945 17.4617 16.7615 16.8339 17.18L12 20.4028L7.16611 17.18C6.5384 16.7616 6.02369 16.1947 5.66763 15.5296C5.31157 14.8645 5.12518 14.1218 5.125 13.3674V5.73771C5.12503 5.56393 5.18431 5.39537 5.29305 5.25982C5.40179 5.12428 5.55349 5.02986 5.72312 4.99215ZM6.65278 6.35035V13.3667C6.65279 13.8697 6.77697 14.3649 7.0143 14.8083C7.25163 15.2518 7.59477 15.6299 8.01326 15.9089L12 18.5672L15.9867 15.9089C16.4051 15.6299 16.7482 15.252 16.9855 14.8087C17.2228 14.3653 17.3471 13.8703 17.3472 13.3674V6.35035L12 5.16326L6.65278 6.35035Z"
                  fill="inherit"
                />
                <path
                  d="M9.1123 12.4907H10.3303C10.3443 12.9177 10.4703 13.2502 10.7083 13.4882C10.9533 13.7192 11.2683 13.8697 11.6533 13.9397V11.7557C10.0573 11.4127 9.2593 10.7337 9.2593 9.71867C9.2593 9.15867 9.48331 8.67567 9.93131 8.26967C10.3793 7.85667 10.9533 7.62217 11.6533 7.56617V6.88367H12.4198V7.57667C13.1408 7.66067 13.7253 7.91617 14.1733 8.34317C14.6283 8.76317 14.8593 9.30217 14.8663 9.96017L13.6378 9.93917C13.5888 9.24617 13.1828 8.82967 12.4198 8.68967V10.8107C13.1618 10.9297 13.7568 11.1712 14.2048 11.5352C14.6528 11.8922 14.8768 12.3717 14.8768 12.9737C14.8768 13.5757 14.6598 14.0692 14.2258 14.4542C13.7988 14.8322 13.1968 15.0387 12.4198 15.0737V15.8612H11.6533V15.0422C10.9323 14.9512 10.3268 14.6992 9.8368 14.2862C9.3538 13.8662 9.1123 13.2782 9.1123 12.5222C9.1123 12.5152 9.1123 12.5047 9.1123 12.4907ZM12.4198 13.9922C12.8118 13.9712 13.1233 13.8732 13.3543 13.6982C13.5853 13.5232 13.7008 13.2922 13.7008 13.0052C13.7008 12.7322 13.5888 12.5152 13.3648 12.3542C13.1478 12.1932 12.8328 12.0602 12.4198 11.9552V13.9922ZM10.4353 9.66617C10.4353 10.1422 10.8413 10.4677 11.6533 10.6427V8.64767C11.2753 8.68267 10.9778 8.79817 10.7608 8.99417C10.5438 9.18317 10.4353 9.40717 10.4353 9.66617Z"
                  fill="inherit"
                />
              </svg>
              {/* <Icon icon="shield-cross-line" className="text-white w-5 h-5" /> */}
            </div>
            <h3 className="text-body-2xs-m text-center">10-Day Guarantee</h3>
            <p className="text-gray-400 text-center text-body-2xs-r !mt-1">
              We stand behind our services with an unbeatable 10-day money-back
              guarantee. Don't see promised results? Get a full refund, no
              questions asked. Enjoy risk-free growth on social media!
            </p>
          </div>

          <Separator />

          {/* Secure Payment Section */}
          <div className="space-y-3">
            <div
              style={{
                boxShadow:
                  "0px 0px 0px 3.125px var(--Green-100, #EFFAF6), 0px 2.5px 5.833px 2.5px var(--Green-300, #38C793) inset",
                border: "3.125px solid rgba(255, 255, 255, 0.56)",
              }}
              className="mx-auto w-10 h-10 rounded-full bg-green-300 flex items-center justify-center"
            >
              <Icon icon="secure-payment-line" className="text-white w-5 h-5" />
            </div>
            <h3 className="text-body-2xs-m text-center">Secure Payment</h3>
            <p className="text-gray-400 text-center text-body-2xs-r !mt-1">
              Rest assured, your transactions are safe. Our leading payment
              processor uses advanced security protocols to keep every payment
              secure. Enjoy peace of mind with your purchase.
            </p>
          </div>

          {/* Payment Methods Section */}
          <div className="space-y-3">
            <div className="flex justify-center items-center">
              <div className="flex space-x-6">
                <CardIconWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                  >
                    <path
                      d="M14.2738 1C12.7827 1 11.4502 1.68152 11.4502 2.94067C11.4502 4.38467 13.8134 4.4844 13.8134 5.20983C13.8134 5.51527 13.4165 5.78871 12.7385 5.78871C11.7763 5.78871 11.0571 5.40665 11.0571 5.40665L10.7494 6.67728C10.7494 6.67728 11.5779 7 12.6778 7C14.308 7 15.5908 6.28501 15.5908 5.00435C15.5908 3.47849 13.2177 3.38171 13.2177 2.70841C13.2177 2.4691 13.5436 2.20694 14.2196 2.20694C14.9824 2.20694 15.6047 2.4848 15.6047 2.4848L15.9059 1.2576C15.9059 1.2576 15.2287 1 14.2738 1ZM0.631817 1.09263L0.595703 1.27786C0.595703 1.27786 1.22301 1.37909 1.78799 1.58105C2.51545 1.81261 2.56729 1.94742 2.6898 2.36613L4.02484 6.90448H5.81453L8.57165 1.09263H6.78608L5.01447 5.04413L4.29156 1.69464C4.22526 1.3113 3.88942 1.09263 3.47836 1.09263H0.631817ZM9.28964 1.09263L7.88893 6.90446H9.59161L10.9874 1.09261H9.28964V1.09263ZM18.7861 1.09263C18.3755 1.09263 18.158 1.28646 17.9983 1.62518L15.5038 6.90446H17.2894L17.6348 6.02457H19.8101L20.0202 6.90446H21.5957L20.2212 1.09263H18.7861ZM19.0183 2.66281L19.5476 4.84369H18.1296L19.0183 2.66281Z"
                      fill="#1434CB"
                    />
                  </svg>
                </CardIconWrapper>
                <CardIconWrapper>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.59076 8C10.7945 8 12.5809 6.20914 12.5809 4C12.5809 1.79086 10.7945 0 8.59076 0C6.38705 0 4.60059 1.79086 4.60059 4C4.60059 6.20914 6.38705 8 8.59076 8Z"
                      fill="#EB001B"
                    />
                    <path
                      d="M13.4101 8C15.6138 8 17.4003 6.20914 17.4003 4C17.4003 1.79086 15.6138 0 13.4101 0C11.2064 0 9.41992 1.79086 9.41992 4C9.41992 6.20914 11.2064 8 13.4101 8Z"
                      fill="#F79E1B"
                    />
                    <path
                      d="M10.9993 0.805664C11.96 1.53547 12.5786 2.692 12.5786 3.9921C12.5786 5.2922 11.96 6.45073 10.9993 7.18054C10.0385 6.45073 9.41992 5.2942 9.41992 3.9921C9.41992 2.69001 10.0405 1.53547 10.9993 0.805664Z"
                      fill="#FF5F00"
                    />
                  </svg>
                </CardIconWrapper>
                <CardIconWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                  >
                    <path
                      d="M12.8147 0.00363962C13.2151 0.00363962 13.5426 0.33121 13.5426 0.731574V7.27207C13.5426 7.67243 13.2151 8 12.8147 8H1.18594C0.785578 8 0.458008 7.67243 0.458008 7.27207V0.727935C0.458008 0.327571 0.785578 0 1.18594 0H12.8147"
                      fill="#286CB4"
                    />
                    <path
                      d="M3.35535 3.36917L3.6356 4.04979H3.07509L3.35535 3.36917ZM9.19702 3.39829H8.11604V3.70038H9.17883V4.1517H8.11604V4.48655H9.19702V4.75589L9.95044 3.9406L9.19702 3.08892V3.39829ZM4.08692 2.54661H5.53915L5.86308 3.24907L6.16154 2.54297H9.9286L10.3217 2.97609L10.7257 2.54297H12.4545L11.177 3.94424L12.4436 5.3346H10.6856L10.2926 4.90148L9.88492 5.3346H3.74115L3.56281 4.90148H3.15153L2.97318 5.3346H1.55371L2.74752 2.54297H3.99229L4.08692 2.54661ZM7.23524 2.93969H6.41995L5.874 4.2245L5.28438 2.93969H4.47637V4.68674L3.7266 2.93969H3.0023L2.13606 4.94151H2.70021L2.87855 4.50839H3.82851L4.00685 4.94151H4.99684V3.51112L5.63378 4.94151H6.06691L6.70021 3.51476V4.94151H7.2316L7.23524 2.93969ZM10.6274 3.94424L11.5483 2.93969H10.8858L10.3035 3.56572L9.73934 2.93969H7.59921V4.94515H9.71022L10.2962 4.31185L10.8604 4.94515H11.541L10.6274 3.94424Z"
                      fill="white"
                    />
                  </svg>
                </CardIconWrapper>
                <CardIconWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_291_8719)">
                      <path
                        d="M2.50402 -0.000976562H5.69295C6.1381 -0.000976562 6.41496 0.361856 6.31112 0.808478L4.82645 7.18276C4.72167 7.62783 4.27598 7.99097 3.83052 7.99097H0.641904C0.19738 7.99097 -0.0801072 7.62783 0.0237368 7.18276L1.50903 0.808478C1.61288 0.361856 2.05833 -0.000976562 2.50402 -0.000976562Z"
                        fill="#E21836"
                      />
                      <path
                        d="M5.42667 -0.000976562H9.09386C9.53893 -0.000976562 9.33824 0.361856 9.23354 0.808478L7.7491 7.18276C7.64494 7.62783 7.67743 7.99097 7.23143 7.99097H3.56424C3.11824 7.99097 2.84223 7.62783 2.94701 7.18276L4.43137 0.808478C4.53677 0.361856 4.9813 -0.000976562 5.42667 -0.000976562Z"
                        fill="#00447C"
                      />
                      <path
                        d="M8.94996 -0.000976562H12.1389C12.5847 -0.000976562 12.8615 0.361856 12.7568 0.808478L11.2724 7.18276C11.1676 7.62783 10.7216 7.99097 10.2759 7.99097H7.08847C6.64246 7.99097 6.36583 7.62783 6.4703 7.18276L7.95497 0.808478C8.05882 0.361856 8.50396 -0.000976562 8.94996 -0.000976562Z"
                        fill="#007B84"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_291_8719">
                        <rect
                          width="12.8"
                          height="8"
                          fill="white"
                          transform="translate(0 -0.000976562)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </CardIconWrapper>
              </div>
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button
            onClick={() => onOpenChange(false)}
            className="w-full !bg-black !text-body-2xs-m"
          >
            <span className="mr-1">Continue to Checkout </span> 👉
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentAssuranceDialog;
