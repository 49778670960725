import mixpanel from "mixpanel-browser";

const MIXPANEL_TOKEN = "ff745f898d129e764cf40bfc9e8cd9a6";

mixpanel.init(MIXPANEL_TOKEN);

const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: Record<string, any>) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: Record<string, any>) => {
      mixpanel.people.set(props);
    },
  },
  setUserProperties: (props: Record<string, any>) => {
    mixpanel.people.set(props);
  },
};

export default Mixpanel;
